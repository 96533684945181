import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ComponentService {

	/**
	 * Current component
	 */
	private component = new BehaviorSubject<Component | null>(null);

	constructor() { }

	/**
	 * Set component as current
	 */
	next(component: Component | null): void {
		this.component.next(component);
	}

	/**
	 * Get current component
	 */
	get(): Component | null {
		return this.component.getValue();
	}

	/**
	 * Subscribe to component changes
	 */
	subscribe(next: (component: Component | null) => void): Subscription {
		return this.component.subscribe(next);
	}
}
