import {
	Component, OnInit,
	Renderer2,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationActionTypes } from 'src/app/enums/confirmation-action-types.enum';
import { ConfirmationButtonTypes } from 'src/app/enums/confirmation-button-types.enum';
import { ConfirmationButton, ConfirmationData } from 'src/app/interfaces/confirmation';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

	/**
	 * Modal template
	 */
	@ViewChild('template') template!: TemplateRef<BsModalRef>;

	/**
	 * Keypress Func
	 */
	keypressFunc: (() => void) | null = null;

	/**
	 * Modal reference
	 */
	modalRef!: BsModalRef;

	/**
	 * Text of the confirmation popup
	 */
	data?: ConfirmationData;
	title?: string;
	message?: string;

	/**
	 * Some enums
	 */
	enums = {
		ButtonTypes: ConfirmationButtonTypes,
	};

	constructor(
		private modalService: BsModalService,
		private confirmationService: ConfirmationService,
		private renderer: Renderer2
	) { }

	ngOnInit(): void {
		this.confirmationService.subscribe((data: ConfirmationData) => {
			this.data = data;
			this.modalRef = this.modalService.show(this.template);

			this.keypressFunc = this.renderer.listen('document', 'keypress', (e: KeyboardEvent) => {

				console.log(e.key);
				if(['Enter','Escape'].indexOf(e.key) === -1){ return; };

				if(this?.data?.actions){
					for (const btn of this.data.actions) {
						if(e.key === 'Enter' && btn.type === ConfirmationButtonTypes.Success){
							return this.onClick(btn);
						}

						if(e.key === 'Esc' && btn.callback === ConfirmationActionTypes.Close){
							return this.close();
						}
					}
				}
			});

			this.modalService.onHide.subscribe(() => {
				if(this.keypressFunc){
					this.keypressFunc();
					this.keypressFunc = null;
				}
			});
		});

	}

	/**
	 * On button click
	 */
	onClick(action: ConfirmationButton): void {

		/**
		 * If is close type
		 */
		if (action.callback === ConfirmationActionTypes.Close) {
			return this.close();
		}

		/**
		 * Call callback
		 */
		if (action.callback) {
			action.callback();
		}

		/**
		 * Check if prevent close
		 */
		if (!action.preventClose) {
			this.close();
		}
	}

	/**
	 * Close popup
	 */
	close(): void {
		this.modalRef.hide();
	}

}
