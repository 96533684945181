/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { AES, enc, lib, mode, pad, SHA256 } from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class CryptingService {

	/**
	 * Inizialization vector for decrypting
	 */
	private static readonly iv = enc.Hex.parse('86ea0c62487e021d86ea0c62487e021d');

	constructor() { }

	/**
	 * Crypt a string using a key
	 */
	crypt(toCrypt: string, key: string): string {
		return AES.encrypt(toCrypt, key).toString();
	}

	/**
	 * Decrypt a string using a key
	 */
	decrypt(toDecrypt: string, key: string): string {
		return AES.decrypt(toDecrypt, key).toString(enc.Utf8);
	}

	/**
	 * New Decrypting
	 */
	newDecrypt(toDecrypt: ArrayBuffer, key: string): ArrayBuffer {

		/**
		 * Create Byte Array [CryptoJs Format]
		 */
		const cryptoByteArray = lib.WordArray.create(toDecrypt as unknown as number[]);

		/**
		 * Make Cipherparams
		 * lib['CipherParams'] Workaraound, cause 'lib.CipherParams' is not declared
		 */
		const cipherParams = lib.CipherParams.create({
			ciphertext: cryptoByteArray
		});

		/**
		 * Convert key to to WordArray
		 */
		const wordArrayKey = SHA256(key);

		/**
		 * Decrypt File
		 */
		const decrypted = AES.decrypt(cipherParams, wordArrayKey, {
			iv: CryptingService.iv,
			mode: mode.CBC,
			padding: pad.Pkcs7
		});

		return this.wordArrayToUint8Array(decrypted);

	}

	/**
	 * Covert wordArray to ArrayBuffer
	 *
	 * @param wordArray -
	 */
	private wordArrayToUint8Array(wordArray: any): ArrayBuffer {
		const len = wordArray.words.length;
		const u8Array = new Uint8Array(len << 2);
		let offset = 0;
		let word;
		let i;

		for (i = 0; i < len; i++) {
			word = wordArray.words[i];
			u8Array[offset++] = word >> 24;
			u8Array[offset++] = (word >> 16) & 0xff;
			u8Array[offset++] = (word >> 8) & 0xff;
			u8Array[offset++] = word & 0xff;
		}
		return u8Array;
	}

	/**
	 * Get URL from An ArrayBuffer
	 *
	 * @param arrayBufferData -
	 */
	// private getURLFromArrayBuffer(arrayBufferData: ArrayBuffer): string {
	// 	const blob = new Blob([arrayBufferData], { type: 'octet/stream' });
	// 	return window.URL.createObjectURL(blob);
	// }
}
