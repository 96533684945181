import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'decimal'
})
export class DecimalPipe implements PipeTransform {
	transform(value: number): string {
		const formatter = new Intl.NumberFormat(undefined, {
			minimumFractionDigits: 2
		});

		return formatter.format(value).replace('.', ',');
	}
}
