import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { GymSubscription } from 'src/app/interfaces/gym-subscription';

@Component({
	selector: 'app-grace-period-dialog',
	templateUrl: './grace-period-dialog.component.html',
	styleUrls: ['./grace-period-dialog.component.scss']
})
export class GracePeriodDialogComponent implements OnInit {

	/**
	 * Get grace period remainig days
	 */
	 get gracePeriodRemainigDays(): number {

		/**
		 * Get grace period duration dates
		 */
		const validTo = moment(this.gymSubscription?.valid_to, 'DD/MM/YYYY');
		const endDate = validTo.clone().add(this.gymSubscription?.grace_period_duration, 'days');

		return endDate.diff(moment(), 'days');
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public gymSubscription: GymSubscription
	) { }
	
	ngOnInit(): void { }
}
