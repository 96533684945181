import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatorFetchParams, TablePaginator } from 'src/app/components/table/table.component';
import { Notification } from 'src/app/interfaces/notification';
import { HttpService } from '../http/http.service';
import { Requests } from 'src/app/enums/requests';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Get list of notifications
	 */
	list(): Promise<Notification[]> {
		return new Promise((resolve: (data: Notification[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getNotifications)
				.then((notifications: Notification[]) => resolve(notifications))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get list of notifications (admin ones)
	 */
	mine(): Promise<Notification[]> {
		return new Promise((resolve: (data: Notification[]) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getMyNotifications)
				.then((notifications: Notification[]) => resolve(notifications))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get list of notifications (paginated)
	 */
	 listPaginated(params: PaginatorFetchParams): Promise<TablePaginator<Notification[]>> {
		return this.http.send(Requests.getNotifications, {
			queryParams: {
				paginated: 'true',
				pageSize: params.page.size.toString(),
				pageIndex: (params.page.index + 1).toString(),
				query: params.query,
				sortColumn: params.sort.key,
				sortDirection: params.sort.direction,
			},
		});
	}

	/**
	 * Create notification
	 */
	create(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.createNotification, { body: formData })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
