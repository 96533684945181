<app-header [navbarBrandRouterLink]="['/', 'dashboard']" [fixed]="true"
	[navbarBrandFull]="{src: 'assets/img/logo_black.png', width: 89, height: 25, alt: 'Crossin Logo'}"
	[navbarBrandMinimized]="{src: 'assets/img/logo_black.png', width: 30, height: 30, alt: 'Crossin Logo'}"
	[sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">
	<ul class="nav navbar-nav mobile position-absolute" style="left:0; right: 0; z-index: -1; justify-content: center;">
		<li class="nav-item">
			<img src="assets/img/logo_black.png" style="height: 30px">
		</li>
	</ul>
	<ul class="nav navbar-nav desktop">
		<li class="nav-item">
			<img src="assets/img/logo_black.png" style="height: 30px;">
		</li>
	</ul>
	<p class="nav navbar-nav desktop cursor-pointer text-danger" *ngIf="isGracePeriod" (click)="showGracePeriodDialog()">
		{{ 'IL_TUO_ABBONAMENTO_CROSS_IN_SCADUTO_HAI'|translate }} {{ gracePeriodRemainigDays }} {{ 'GIORNI_PER_RINNOVARE'|translate }}.<br>
	</p>
	<ul class="nav navbar-nav ml-auto justify-content-end">
		<!-- <li class="nav-item d-md-down-none">
				<a class="nav-link" href="#"><i class="icon-bell"></i><span
						class="badge badge-pill badge-danger">5</span></a>
			</li> -->
		<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf='languages'>
			<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
				(click)="false">
				<!-- <i class="fa fa-globe fa-3x"></i> -->
				<span class='flag-icon fa-2x'
					[ngClass]='languageService.getLanguage() !== "en" ? ("flag-icon-" + languageService.getLanguage()) : "flag-icon-gb"'></span>
			</a>
			<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
				<ng-container *ngFor='let obj of languages | keyvalue'>
					<a class="dropdown-item" (click)="setLanguage(obj.key)">
						<span class='flag-icon'
							[ngClass]='obj.key !== "en" ? ("flag-icon-" + obj.key) : "flag-icon-gb"'></span> {{
						obj.value }}
					</a>
				</ng-container>
			</div>
		</li>
		<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="admin">
			<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
				(click)="false">
				<i class="bi bi-bell-fill" [ngStyle]="{'font-size': '18px'}"></i>
				<span *ngIf="newNotifications > 0" class="badge badge-pill badge-danger">{{ newNotifications
					}}</span>
			</a>
			<ng-container *ngIf="notifications.length > 0">
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<a class="dropdown-item notification" [ngClass]="{active: !notification.seen}"
						*ngFor="let notification of notifications">
						<div class="d-flex justify-content-between align-items-center mb-2">
							<h5 class="m-0">{{ notification.title }}</h5>
							<small>{{ notification.created_at }}</small>
						</div>
						<p class="m-0">{{notification.text}}</p>
					</a>
				</div>
			</ng-container>
		</li>
		<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="admin">
			<a class="nav-link img-user" href="#" role="button" aria-haspopup="true" aria-expanded="false"
				dropdownToggle (click)="false">
				<img [src]="admin.gym?.logo_url || 'assets/img/logo.png'" class="img-avatar" [alt]="admin.gym?.email">
			</a>
			<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
				<a class="dropdown-item" [routerLink]="['/', 'settings']">
					<i class="fa fa-wrench"></i>
					{{ "NAV.SETTINGS"|translate }}
				</a>
				<a class="dropdown-item" [routerLink]="['/', 'gym-subscription']">
					<i class="bi bi-receipt"></i>
					{{ 'IL_MIO_ABBONAMENTO'|translate }}
				</a>
				<a class="dropdown-item" (click)="logout()">
					<i class="fa fa-lock"></i>
					{{ "GENERIC.LOGOUT"|translate }}
				</a>
			</div>
		</li>
	</ul>
</app-header>
<div class="app-body">
	<ng-container>
		<app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
			(minimizedChange)="toggleMinimize($event)" *ngIf='navItems'>
			<app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized">
			</app-sidebar-nav>
			<app-sidebar-minimizer></app-sidebar-minimizer>
		</app-sidebar>
	</ng-container>
	<main class="main">
		<div class="container-fluid mt-4">
			<router-outlet (activate)="onComponentChange($event)"></router-outlet>
		</div>
	</main>
</div>
<app-footer class="d-flex justify-content-between">
	<span><a href="https://cross-in.com/">Cross In </a> &copy; {{ moment().format('YYYY') }}</span>
	<span>Powered by <a href="https://camacrea.it">Camacrea</a></span>
	<span *ngIf="version">Version {{ version }}</span>
</app-footer>
