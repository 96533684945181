<section class="table">
	<ng-container *ngIf="dataSource">
		<div class="row" *ngIf="actions || search">
			<div class="d-flex align-items-center">
				<button class="btn btn-{{ button.color }} mr-3" type="button" *ngFor="let button of actions" (click)="button.onClick($event)">
					{{ button.label }}
				</button>
				<button class="btn btn-warning mr-3" type="button" *ngIf="exporter && export?.enabled" (click)="exportTable(exporter)">
					{{ "ESPORTA" | translate }}
				</button>
				<ng-content select="[actions]"></ng-content>
			</div>
			<div class="filters">
				<mat-form-field class="mr-3" *ngIf="dateFilter?.enabled">
					<mat-label>{{ "DATA_DAL" | translate }} - {{ "DATA_AL" | translate }}</mat-label>
					<mat-date-range-input [rangePicker]="picker" (click)="picker.open()"
						[formGroup]="dates">
						<input matStartDate formControlName="startDate" />
						<input matEndDate formControlName="endDate" />
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
					<i matSuffix (click)="dates.reset()" class="bi bi-x"></i>
				</mat-form-field>
				<mat-form-field *ngIf="search?.enabled">
					<mat-label>{{ "GENERIC.SEARCH" | translate }}</mat-label>
					<input matInput #query [(ngModel)]="queryModel" />
				</mat-form-field>
			</div>
		</div>

		<mat-table matSort [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumnIndexes">
			<!-- Columns -->
			<ng-container *ngFor="let content of contents">
				<ng-container [matColumnDef]="content.key" *ngIf="content.key">
					<mat-header-cell class="align-{{ content.align }}" *matHeaderCellDef mat-sort-header [disabled]="!sort?.keys?.includes(content.key)">
						{{ content.label }}
					</mat-header-cell>
					<mat-cell class="align-{{ content.align }} align-{{ content.alignCell }}" *matCellDef="let item">
						<ng-template [ngTemplateOutlet]="content.templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
					</mat-cell>
					<mat-footer-cell *matFooterCellDef="let item" class="align-{{ content.align }} align-{{ content.alignCell }}">
						<ng-container *ngIf="footer && footer.enabled && footer.columns && footer.columns[content.key]">
							{{ footer.columns[content.key](item) }}
						</ng-container>
					</mat-footer-cell>
				</ng-container>
			</ng-container>

			<!-- Header and Row Declarations -->
			<mat-header-row *matHeaderRowDef="rowKeys"></mat-header-row>
			<mat-row *matRowDef="let item; columns: rowKeys" (click)="itemClick.emit(item)"></mat-row>
			<ng-container *ngIf="footer && footer.enabled">
				<mat-footer-row *matFooterRowDef="rowKeys"></mat-footer-row>
			</ng-container>


			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row no-data-row" *matNoDataRow>
				<td class="mat-cell">{{ noDataText || "NON_CI_SONO_ELEMENTI_PRESENTI_NELLA_TABELLA" | translate }}</td>
			</tr>
		</mat-table>
	</ng-container>

	<!-- Paginator -->
	<mat-paginator *ngIf="paginator?.enabled" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [length]="dataLength"></mat-paginator>
</section>
