<div class="app-body" [appBackgroundImage]="'assets/img/bg_login.png'">
	<main class="main d-flex align-items-center">
		<div class="container">
			<div class="row">
				<div class="col-md-4 mx-auto">
					<div class="card-group">
						<div class="card p-4">
							<div class="card-body">
								<form [formGroup]="form" (submit)='resetPassword($event)'>
									<h1>{{ "RESET-PASSWORD.TITLE"|translate }}</h1>
									<p class="text-muted">{{ "RESET-PASSWORD.SUBTITLE"|translate }}</p>
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-lock"></i></span>
										</div>
										<input type="password" class="form-control" [ngClass]="{ 'is-invalid': !!form.get('password')?.errors }" placeholder="{{ 'PASSWORD'|translate }}" formControlName='password' autocomplete="new-password">
									</div>
									<div class="input-group mb-4">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-lock"></i></span>
										</div>
										<input type="password" class="form-control" [ngClass]="{ 'is-invalid': !!form.get('password_confirmation')?.errors }" placeholder="{{ 'FORM.PASSWORD_CONFIRMATION'|translate }}" formControlName='password_confirmation' autocomplete="new-password">
									</div>
									<div class="row">
										<div class="col-6">
											<input type="submit" class="btn btn-primary px-4" value="{{ 'CAMBIA_PASSWORD'|translate }}">
										</div>
										<div class="col-6 text-right">
											<!-- TODO -->
											<!-- <button type="button" class="btn btn-link px-0">Forgot password?</button> -->
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>
