import {
	HttpErrorResponse, HttpEvent, HttpHandler,
	HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Errors } from '../enums/errors.enum';
import { AdminService } from '../services/admin/admin.service';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

	constructor(
		private adminService: AdminService,
		private toast: ToastService,
		private injector: Injector
	) { }

	/**
	 * Bail out when session timeout
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// No retry
			retry(0),
			catchError((error: HttpErrorResponse) => {

				/**
				 * Translator must be injected in runtime in interceptors
				 */
				const translate = this.injector.get(TranslateService);

				/**
				 * Session expired?
				 */
				if (error?.error?.code === Errors.ExpiredToken) {
					this.adminService.logout();
					this.toast.error(translate.instant('ERRORS.SESSION_EXPIRED'));
				}

				return throwError(() => error);
			})
		);
	}
}
