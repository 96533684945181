import {
	Component,
	OnInit
} from '@angular/core';
import {
	Event, NavigationStart, Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ComponentService } from './services/component/component.service';
import { LanguageService } from './services/language/language.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'body',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	constructor(
		private router: Router,
		private languageService: LanguageService,
		private componentService: ComponentService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * On route change
		 */
		this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((event: Event) => {

			/**
			 * Scroll up on page change
			 */
			window.scrollTo(0, 0);

			/**
			 * Close the modal (made to counter history back)
			 */
			($('.modal') as any).modal('hide');
		});

		/**
		 * Set app's language
		 */
		this.languageService.initLanguage();
	}

	/**
	 * On omponent change
	 */
	onComponentChange(component: Component): void {
		this.componentService.next(component);
	}
}
