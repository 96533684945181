import { Injectable, isDevMode } from '@angular/core';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { CryptingService } from '../crypting/crypting.service';

declare global {
	interface Window { listLocalStorage: any; clearLocalStorage: any }
}

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	/**
	 * Key Prefiexs
	 */
	private localStorageKeyPrefix = 'STORAGE-';

	/**
	 * Key length
	 */
	private localStorageKeylength = 500;

	/**
	 * Crypting Key
	 */
	// private cryptingKey = '1234567890QWERTYUIOASDHJKLZXCVBNMasdjklqwiozxcvbnm.-+è.òèòò._:;§°çP*éP';
	private cryptingKey = '24680WRYIADJLXVNadkqizcbm-èòò.:§ç*P';

	/**
	 * If we are in development mode
	 */
	private isDev = isDevMode();

	/**
	 * Our data
	 */
	private data: any = {};

	/**
	 * Saving timeout
	 * [For preventing long time concatenated encryption]
	 */
	private saveTimeout: any = null;

	constructor(
		private cryptingService: CryptingService,
	) {

		/**
		 * Check if LocalStorage is already encrypted
		 * if not encrypt it and remove old objects
		 */
		this.encryptStorageIfNecessary();

		/**
		 * Set function in DOM for showing real list
		 * [Only in DEV MODE]
		 */
		if (this.isDev) {
			window.listLocalStorage = () => this.data;
			window.clearLocalStorage = () => this.clear();
		}
	}

	/**
	 * Get Item from LocalStorage
	 */
	getItem(key: LocalStorageTypes): any | null {

		const result = typeof (this.data[key] !== 'undefined' || this.data[key] !== null) ? this.data[key] : null;

		// /**
		//  * Filter old Local storage object we don't need anaymore
		//  */
		// switch (key) {
		// 	case LocalStorageTypes.AlwaysOnNotificationFilters:

		// 		if (result && typeof result.timelapse !== 'undefined') {
		// 			this.removeItem(key);
		// 			this.removeItem(LocalStorageTypes.AlwaysOnNotificationSearchId);
		// 		}
		// 		break;

		// 	case 'ALWAYSON_REQUEST' as LocalStorageTypes:
		// 		this.removeItem(key);
		// 		break;


		// 	default:
		// 		break;
		// }

		return result;
	}

	/**
	 * Delete item from LocalStorage
	 */
	removeItem(key: string): void {

		/**
		 * Remove item internally
		 */
		if (typeof this.data[key] !== 'undefined') {
			delete this.data[key];

			/**
			 * Re-write Storage
			 */
			this.save();
		}

	}

	/**
	 * Clear Local Service
	 */
	clear(): void {

		/**
		 * Empty data
		 */
		this.data = {};

		/**
		 * Save
		 */
		this.save();
	}

	/**
	 * Set Item in LocalStorage
	 */
	setItem(key: string, value: any): void {

		/**
		 * Save internally
		 */
		this.data[key] = value;

		/**
		 * Save
		 */
		this.save();
	}

	/**
	 * Encrypt storage if necessary [only one time]
	 */
	private encryptStorageIfNecessary(): void {

		/**
		 * Extract all keys
		 */
		const keys = Object.keys(localStorage);

		/**
		 * if there are no keys [storage empty] we do nothing...
		 */
		if (keys.length === 0) {
			return;
		}

		/**
		 * Check for first key ['STORAGE-1']
		 * if is present we have already encrypted
		 */
		if (keys.indexOf(this.localStorageKeyPrefix + 1) !== -1) {

			/**
			 * Extract encrypted
			 */
			this.extract();
			// console.log('already exists')
			return;
		}

		// console.log('not encrypted')

		/**
		 * Otherwise Save data in service
		 */
		this.data = JSON.parse(JSON.stringify(localStorage));

		/**
		 * Save data in LocalStroage
		 */
		this.save();
	}

	/**
	 * Save data in LocalStorage
	 */
	private save(): void {

		/**
		 * Clear old timeout if exists
		 */
		const clearLastTimeout = (): void => {
			if (this.saveTimeout) {
				clearTimeout(this.saveTimeout);
				this.saveTimeout = null;
			}
		};

		/**
		 * Clear old timeout if exists
		 */
		clearLastTimeout();

		/**
		 * Call function for saving
		 */
		this.saveTimeout = setTimeout(() => {

			/**
			 * Remove old keys
			 */
			Object.keys(localStorage).forEach((key) => {

				/**
				 * Check if index is valid
				 * [if we have PREFIX in key name]
				 */
				if (key.indexOf(this.localStorageKeyPrefix) === -1) { return; }

				/**
				 * Remove key
				 */
				localStorage.removeItem(key);
			});

			/**
			 * Encrypt JSON
			 */
			const encrypted = this.cryptingService.crypt(JSON.stringify(this.data), this.cryptingKey);

			/**
			 * Divide in slices
			 */
			const chunks: any = [];
			for (let i = 0, length = encrypted.length; i < length; i += this.localStorageKeylength) {
				chunks.push(encrypted.substring(i, i + this.localStorageKeylength));
			}

			/**
			 * Clear LocalStorage
			 */
			localStorage.clear();

			/**
			 * Save
			 */
			setTimeout(() => {
				chunks.forEach((value: any, index: any) => {
					localStorage.setItem(this.localStorageKeyPrefix + (index + 1), value);
				});
			}, 0);

			/**
			 * Clear timeout
			 */
			clearLastTimeout();

		}, 250);
	}


	/**
	 * Extract data, decrypt and save in localService
	 */
	private extract(): void {
		const chunks: any = {};

		/**
		 * Extract only valid indexes
		 */
		Object.keys(localStorage).forEach((key) => {

			/**
			 * Check if index is valid
			 * [if we have PREFIX in key name]
			 */
			if (key.indexOf(this.localStorageKeyPrefix) === -1) { return; }

			/**
			 * Get index
			 */
			const index = key.substring(key.indexOf('-') + 1);

			/**
			 * Save chunk
			 */
			chunks[index] = localStorage.getItem(key);
		});


		/**
		 * Reorder chunks by key and re-create initial encrypted string
		 */
		let encrypted = '';
		Object.keys(chunks).sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1).forEach((key) => encrypted += chunks[key]);

		/**
		 * Decrypt String
		 */
		const decrypted = this.cryptingService.decrypt(encrypted, this.cryptingKey);

		/**
		 * Re-create JSON
		 */
		this.data = JSON.parse(decrypted);
	}
}
