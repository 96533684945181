import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from '../../enums/errors.enum';
import { AdminService } from '../../services/admin/admin.service';
import { LanguageService } from '../../services/language/language.service';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	languages: { [key: string]: string } = {};

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		name: '',
		email: '',
		password: '',
		password_confirmation: '',
	});

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private adminService: AdminService,
		private toast: ToastService,
		private loader: LoaderService,
		public languageService: LanguageService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Load languages
		 */
		this.languages = this.languageService.getAvailableLanguages();

		/**
		 * Rdirect if authenticated
		 */
		if (this.adminService.isAuthenticated()) {
			this.router.navigateByUrl('/dashboard');
		}
	}

	/**
	 * Register
	 */
	register(event: Event): void {
		event.preventDefault();

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Send the lang
		 */
		formData.append('lang', this.languageService.getLanguage());

		/**
		 * Register request
		 */
		this.loader.show();
		this.adminService.register(formData)
			.then(() => {
				this.loader.hide();
				this.toast.show(this.translate.instant('REGISTRAZIONE_EFFETUATA_CON_SUCCESSO'));

				/**
				 * Redirect login
				 */
				this.router.navigateByUrl('/login');
			})
			.catch((error: HttpErrorResponse) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.Validation:
						this.toast.error(this.translate.instant('ERRORE_DI_VALIDAZIONE_ASSICURATI_DI_AVER_AVER_INSERITO_LA_TUA_MAIL_E_PASSWORD_CORRETAMENTE'));
						for (const key in error.error.validation) {
							if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {

								switch (error.error.validation[key][0]) {
									case 'email-taken':
										this.form.get(key)?.setErrors({
											emailTaken: true
										});
										break;
									case 'password-mismatch':
										this.form.get(key)?.setErrors({
											passwordMismatch: true
										});
										break;
									case 'password-length':
										this.form.get(key)?.setErrors({
											passwordLength: true
										});
										break;

									default:
										this.form.get(key)?.setErrors({
											required: true
										});
										break;
								}
							}
						}
						break;
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	setLanguage(val: string): void {
		this.languageService.setLanguage(val);
	}
}
