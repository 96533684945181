import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Errors } from 'src/app/enums/errors.enum';
import { Requests } from 'src/app/enums/requests';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

interface Request {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'; // Carefull with put since laravel can't read it
	responseType?: 'text' | 'json' | 'arraybuffer' | 'blob' | undefined;
	external?: boolean;
	observeHeaders?: boolean;
};

interface RequestOptions {
	body?: { [key: string]: any };
	urlParams?: { [key: string]: string };
	queryParams?: { [key: string]: string };
	headers?: { [key: string]: string };
};

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	/**
	 * Conversion table to pass from Requests enum to Request object
	 */
	private readonly conversionTable: { enum: Requests; object: Request }[] = [
		{
			enum: Requests.translate,
			object: {
				url: 'https://weblate.camacrea.it/api/translations/cross-in/backend/{lang}/file/',
				method: 'GET',
				external: true
			}
		},
		{
			enum: Requests.getGym,
			object: {
				url: '/gym',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateGym,
			object: {
				url: '/gym',
				method: 'PUT'
			}
		},
		{
			enum: Requests.getDelays,
			object: {
				url: '/delay',
				method: 'GET'
			}
		},
		{
			enum: Requests.stopDelay,
			object: {
				url: '/delay/stop',
				method: 'POST'
			}
		},
		{
			enum: Requests.startDelay,
			object: {
				url: '/delay/start',
				method: 'POST'
			}
		},
		{
			enum: Requests.setupStripeAccount,
			object: {
				url: '/stripe/setup',
				method: 'GET',
				responseType: 'text'
			}
		},
		{
			enum: Requests.getStripeAccount,
			object: {
				url: '/stripe',
				method: 'GET'
			}
		},
		{
			enum: Requests.login,
			object: {
				url: '/login',
				method: 'POST'
			}
		},
		{
			enum: Requests.register,
			object: {
				url: '/register',
				method: 'POST'
			}
		},
		{
			enum: Requests.getAdminWithGym,
			object: {
				url: '/admin',
				method: 'GET'
			}
		},
		{
			enum: Requests.resetPassword,
			object: {
				url: '/change-password',
				method: 'POST'
			}
		},
		{
			enum: Requests.checkResetPasswordCode,
			object: {
				url: '/check-forget-code',
				method: 'POST'
			}
		},
		{
			enum: Requests.setAdminLanguage,
			object: {
				url: '/admin/set-language',
				method: 'POST'
			}
		},
		{
			enum: Requests.getPosts,
			object: {
				url: '/posts',
				method: 'GET'
			}
		},
		{
			enum: Requests.createPost,
			object: {
				url: '/posts',
				method: 'POST'
			}
		},
		{
			enum: Requests.getPost,
			object: {
				url: '/posts/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.updatePost,
			object: {
				url: '/posts/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deletePost,
			object: {
				url: '/posts/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getNotifications,
			object: {
				url: '/notifications',
				method: 'GET'
			}
		},
		{
			enum: Requests.getMyNotifications,
			object: {
				url: '/notifications/mine',
				method: 'GET'
			}
		},
		{
			enum: Requests.createNotification,
			object: {
				url: '/notifications',
				method: 'POST'
			}
		},
		{
			enum: Requests.getAthletes,
			object: {
				url: '/athletes',
				method: 'GET'
			}
		},
		{
			enum: Requests.getAthlete,
			object: {
				url: '/athletes/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getCoaches,
			object: {
				url: '/coaches',
				method: 'GET'
			}
		},
		{
			enum: Requests.getUsers,
			object: {
				url: '/users',
				method: 'GET'
			}
		},
		{
			enum: Requests.getUser,
			object: {
				url: '/users/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createUser,
			object: {
				url: '/users',
				method: 'POST'
			}
		},
		{
			enum: Requests.deleteUser,
			object: {
				url: '/users/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.updateUser,
			object: {
				url: '/users/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.uploadUserDocument,
			object: {
				url: '/users/upload-document/{id}',
				method: 'POST'
			}
		},
		{
			enum: Requests.validateUserDocument,
			object: {
				url: '/users/validate-document/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.getSchedulers,
			object: {
				url: '/schedulers',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateScheduler,
			object: {
				url: '/schedulers/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.toggleScheduler,
			object: {
				url: '/schedulers/toggle',
				method: 'POST'
			}
		},
		{
			enum: Requests.getScheduler,
			object: {
				url: '/schedulers/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.disableScheduler,
			object: {
				url: '/schedulers/disable/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getMembershipFee,
			object: {
				url: '/membership-fees',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSubscriptions,
			object: {
				url: '/subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSubscription,
			object: {
				url: '/subscriptions/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createSubscription,
			object: {
				url: '/subscriptions',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateSubscription,
			object: {
				url: '/subscriptions/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deleteSubscription,
			object: {
				url: '/subscriptions/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getSubscriptionTypes,
			object: {
				url: '/subscriptions-types',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSubscriptionCadences,
			object: {
				url: '/subscriptions-cadences',
				method: 'GET'
			}
		},
		{
			enum: Requests.getUserSubscriptions,
			object: {
				url: '/user-subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSales,
			object: {
				url: '/sales',
				method: 'GET'
			}
		},
		{
			enum: Requests.getSale,
			object: {
				url: '/sales/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createSale,
			object: {
				url: '/sales',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateSale,
			object: {
				url: '/sales/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deleteSale,
			object: {
				url: '/sales/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.validateProductSale,
			object: {
				url: '/sales/validate-product',
				method: 'POST'
			}
		},
		{
			enum: Requests.removeProductSale,
			object: {
				url: '/sales/remove-product/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getRenewals,
			object: {
				url: '/renewals',
				method: 'GET'
			}
		},
		{
			enum: Requests.deleteRenewal,
			object: {
				url: '/renewals/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getCurrencies,
			object: {
				url: '/currencies',
				method: 'GET'
			}
		},
		{
			enum: Requests.getMeasurementSystems,
			object: {
				url: '/measurement-systems',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPaymentMethods,
			object: {
				url: '/payment-methods',
				method: 'GET'
			}
		},
		{
			enum: Requests.getLessons,
			object: {
				url: '/lessons',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGroupedByDayForWeekLessons,
			object: {
				url: '/lessons/group-by-day-for-week/{date}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getLessonsByCoach,
			object: {
				url: '/lessons/coach/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getLesson,
			object: {
				url: '/lessons/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.massCreateLesson,
			object: {
				url: '/lessons/mass',
				method: 'POST'
			}
		},
		{
			enum: Requests.massUpdateLesson,
			object: {
				url: '/lessons/mass/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.cancelLesson,
			object: {
				url: '/lessons/cancel/{id}/{date}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.restoreLesson,
			object: {
				url: '/lessons/restore/{id}/{date}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deleteLesson,
			object: {
				url: '/lessons/delete/{id}/{date}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getLessonCheckins,
			object: {
				url: '/lessons/checkins/{date}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getNextTodayLesson,
			object: {
				url: '/lessons/next-today',
				method: 'GET'
			}
		},
		{
			enum: Requests.getWorkoutByDiscipline,
			object: {
				url: '/workouts/{id}/{date}',
				method: 'GET'
			}
		},
		{
			enum: Requests.createWorkout,
			object: {
				url: '/workouts',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateWorkout,
			object: {
				url: '/workouts',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deleteWorkout,
			object: {
				url: '/workouts/{id}/{date}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getTodayWorkout,
			object: {
				url: '/workouts/today',
				method: 'GET'
			}
		},
		{
			enum: Requests.getWorkoutResultsByUser,
			object: {
				url: '/workout-results/{user_id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getDisciplines,
			object: {
				url: '/disciplines',
				method: 'GET'
			}
		},
		{
			enum: Requests.createDiscipline,
			object: {
				url: '/disciplines',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateDiscipline,
			object: {
				url: '/disciplines/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.deleteDiscipline,
			object: {
				url: '/disciplines/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getGroupedByDayForWeekDisciplines,
			object: {
				url: '/disciplines/group-by-day-for-week/{date}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getStatistics,
			object: {
				url: '/statistics',
				method: 'POST'
			}
		},
		{
			enum: Requests.getPersonalRecords,
			object: {
				url: '/personal-records/records',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPersonalRecordUnits,
			object: {
				url: '/personal-records/units',
				method: 'GET'
			}
		},
		{
			enum: Requests.getCheckins,
			object: {
				url: '/checkins',
				method: 'GET'
			}
		},
		{
			enum: Requests.checkin,
			object: {
				url: '/checkins/checkin',
				method: 'POST'
			}
		},
		{
			enum: Requests.checkout,
			object: {
				url: '/checkins/checkout',
				method: 'POST'
			}
		},
		{
			enum: Requests.getPRBenchmark,
			object: {
				url: '/statistics/personal-records/{exerciceId}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPersonalRecordsExercices,
			object: {
				url: '/personal-records/exercices',
				method: 'GET'
			}
		},
		{
			enum: Requests.uploadSupportAttachment,
			object: {
				url: '/support/upload-attachment',
				method: 'POST'
			}
		},
		{
			enum: Requests.createSupportRequest,
			object: {
				url: '/support',
				method: 'POST'
			}
		},
		{
			enum: Requests.getTodayLessons,
			object: {
				url: '/lessons/today',
				method: 'GET'
			}
		},
		{
			enum: Requests.removeStripePaymentMethod,
			object: {
				url: '/stripe/payment-methods/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getStripePaymentMethods,
			object: {
				url: '/stripe/payment-methods',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGymAvailableSubscriptions,
			object: {
				url: '/gym/available-subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getCurrentGymSubscription,
			object: {
				url: '/gym/current-gym-subscription',
				method: 'GET'
			}
		},
		{
			enum: Requests.changeGymSubscription,
			object: {
				url: '/gym/change-gym-subscription',
				method: 'POST'
			}
		},
		{
			enum: Requests.scheduleGymSubscription,
			object: {
				url: '/gym/schedule-gym-subscription',
				method: 'POST'
			}
		},
		{
			enum: Requests.requestGymSubscription,
			object: {
				url: '/gym/request-gym-subscription',
				method: 'POST'
			}
		},
		{
			enum: Requests.applyGymPromoCode,
			object: {
				url: '/gym/apply-gym-promo-code',
				method: 'POST'
			}
		},
		{
			enum: Requests.confirmStripePaymentIntent,
			object: {
				url: '/stripe/confirm-payment-intent',
				method: 'POST'
			}
		},
		{
			enum: Requests.getFutureGymSubscription,
			object: {
				url: '/gym/future-gym-subscription',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGymInvoices,
			object: {
				url: '/gym/invoices',
				method: 'GET'
			}
		},
		{
			enum: Requests.cancelCurrentGymSubscription,
			object: {
				url: '/gym/current-gym-subscription',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.cancelFutureGymSubscription,
			object: {
				url: '/gym/future-gym-subscription',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.payUnpaidInvoices,
			object: {
				url: '/invoices/pay-unpaid',
				method: 'POST'
			}
		},
		{
			enum: Requests.getEInvoices,
			object: {
				url: '/e-invoicing',
				method: 'GET'
			}
		},
		{
			enum: Requests.setupEInvoicing,
			object: {
				url: '/e-invoicing/setup',
				method: 'POST'
			}
		},
		{
			enum: Requests.getEInvoicingAccount,
			object: {
				url: '/e-invoicing/account',
				method: 'GET'
			}
		},
		{
			enum: Requests.disconnectEInvoicing,
			object: {
				url: '/e-invoicing/account',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.exportTable,
			object: {
				url: '/export/{id}',
				method: 'POST',
				responseType: 'blob'
			},
		},
		{
			enum: Requests.getAccountings,
			object: {
				url: '/accountings',
				method: 'GET'
			},
		},
		{
			enum: Requests.getUserDocuments,
			object: {
				url: '/users/{id}/documents',
				method: 'GET'
			}
		},
		{
			enum: Requests.deleteUserDocument,
			object: {
				url: '/users/documents/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getPreferences,
			object: {
				url: '/preferences',
				method: 'GET'
			}
		},
		{
			enum: Requests.setPreferences,
			object: {
				url: '/preferences',
				method: 'POST'
			}
		},
		{
			enum: Requests.getProducts,
			object: {
				url: '/products',
				method: 'GET'
			}
		},
		{
			enum: Requests.getProduct,
			object: {
				url: '/products/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.deleteProduct,
			object: {
				url: '/products/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.createProduct,
			object: {
				url: '/products',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateProduct,
			object: {
				url: '/products/{id}',
				method: 'POST'
			}
		},
		{
			enum: Requests.deleteProductVariant,
			object: {
				url: '/products/{id}/variant',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.updateProductCombination,
			object: {
				url: '/products/combination',
				method: 'POST'
			}
		},
		{
			enum: Requests.getProductCombinations,
			object: {
				url: '/products/{id}/combinations',
				method: 'GET'
			}
		},
	];

	constructor(
		private http: HttpClient,
		private loader: LoaderService
	) { }

	/**
	 * Send http request to server
	 */
	send<T = any>(request: Requests, options?: RequestOptions): Promise<T | any> {
		return new Promise((resolve: (response: T | any) => void, reject: (error?: HttpErrorResponse) => void) => {

			/**
			 * Convert the request from enum to object
			 */
			const requestObj = this.resolveRequest(request);

			/**
			 * Reject if cast unsuccsessfull
			 */
			if (!requestObj) {
				console.error('This request does not exist!');
				reject();
			} else {

				/**
				 * Replace params in the url
				 */
				for (const key in options?.urlParams) {
					if (Object.prototype.hasOwnProperty.call(options?.urlParams, key)) {
						requestObj.url = requestObj.url.replace(`{${key}}`, options?.urlParams[key] || '');
					}
				}

				/**
				 * Append query params to the url
				 */
				if (options?.queryParams) {

					/**
					 * Start the query params
					 */
					requestObj.url += '?';
					for (const key in options?.queryParams) {
						if (Object.prototype.hasOwnProperty.call(options?.queryParams, key)) {
							requestObj.url += `${key}=${options?.queryParams[key]}&`;
						}
					}

					/**
					 * Remove trailing &
					 */
					requestObj.url = requestObj.url.replace(/\&$/, '');
				}

				/**
				 * If url is not external
				 */
				if (!requestObj.external) {
					requestObj.url = this.getBaseUrl() + requestObj.url;
				}

				/**
				 * Add headers if requested
				 */
				const headers = new HttpHeaders(options?.headers || {});

				/**
				 * If the request is a PUT with a FormData instance
				 */
				if (requestObj.method === 'PUT' && options?.body instanceof FormData) {

					/**
					 * * Set the put method in the form data and fire a post instead
					 * * PHP put method can't process form data so we need to do this work around
					 */
					options?.body.append('_method', 'PUT');
					requestObj.method = 'POST';
				}

				/**
				 * HTTP Request
				 */
				firstValueFrom(this.http.request(requestObj.method as string, requestObj.url, {
					responseType: requestObj.responseType || 'json',
					body: options?.body,
					observe: requestObj.observeHeaders ? 'response' : 'body',
					headers
				}))
					.then((response: T | HttpResponse<T>) => resolve(response))
					.catch((error: HttpErrorResponse) => {
						this.loader.hide();
						switch (error.error.code) {

							/**
							 * Interceptor errors
							 */
							case Errors.ExpiredToken:
							case Errors.LackGymCode:
							case Errors.NotActivated:
							case Errors.UnpaidInvoices:
							case Errors.GymNotFound: break;

							default:
								reject(error);
								break;
						}
					});
			}
		});
	}

	downloadFile(blob: Blob, name: string): void {

		// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
		const blobUrl = URL.createObjectURL(blob);

		// Create a link element
		const link = document.createElement('a');

		// Set link's href to point to the Blob URL
		link.href = blobUrl;
		link.download = name;

		// Append link to the body
		document.body.appendChild(link);

		// Dispatch click event on the link
		// This is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		);

		// Remove link from body
		document.body.removeChild(link);
	}

	/**
	 * Converts the requests string from the enum to a Request object
	 */
	private resolveRequest(request: Requests): Request | undefined {

		/**
		 * Find it in the conveersion table and return the object
		 */
		return Object.assign({}, this.conversionTable.find(item => item.enum === request)?.object);
	}

	/**
	 * Get base url
	 */
	private getBaseUrl(): string {
		return environment.apiUrl;
	}
}
