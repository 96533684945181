import {
	Component,
	OnInit
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-thank-you-reset-password',
	templateUrl: './thank-you-reset-password.component.html',
	styleUrls: ['./thank-you-reset-password.component.scss']
})
export class ThankYouResetPasswordComponent implements OnInit {

	constructor(
		private meta: Meta
	) { }

	ngOnInit(): void {
		this.meta.addTag({
			name: 'apple-itunes-app',
			content: 'app-id=1525874841'
		});
	}
}
