/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-fallthrough */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod as StripePaymentMethod, StripeCardElementOptions } from '@stripe/stripe-js';
import moment from 'moment';
import { StripeCardComponent, StripeService as NgxStripeService } from 'ngx-stripe';
import { firstValueFrom } from 'rxjs';
import { ConfirmationButtonTypes } from 'src/app/enums/confirmation-button-types.enum';
import { Errors } from 'src/app/enums/errors.enum';
import { BackendSubscription } from 'src/app/interfaces/backend-subscription';
import { GymSubscription } from 'src/app/interfaces/gym-subscription';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { GymService } from 'src/app/services/gym/gym.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StripeService } from '../../services/stripe/stripe.service';
import { ApplyPromoCodeDialog } from './apply-promo-code-dialog/apply-promo-code-dialog';

type PaymentMethod = 'stripe' | 'bonifico-bancario';

@Component({
	selector: 'app-buy-subscription',
	templateUrl: './buy-subscription.component.html',
	styleUrls: ['./buy-subscription.component.scss']
})
export class BuySubscriptionComponent implements OnInit {

	/**
	 * Stripe card element
	 */
	@ViewChild(StripeCardComponent, { static: false }) card?: StripeCardComponent;

	/**
	 * User's payments methods
	 */
	stripePaymentMethods: StripePaymentMethod[] = [];

	/**
	 * Stripe options for styling, language and other things
	 */
	cardOptions: StripeCardElementOptions = {
		iconStyle: 'solid',
		hidePostalCode: true,
		style: {
			base: {
				fontWeight: '600',
				fontFamily: 'Bebas Neue',
				fontSize: '15px',
				fontSmoothing: 'antialiased',
				'::placeholder': { color: '#aaa' },
				':-webkit-autofill': { color: '#fce883' }
			},
			invalid: {
				iconColor: '#ff0000',
				color: '#ff0000'
			},
		}
	};

	languages: { [key: string]: string } = {};

	/**
	 * Is the page in change mode
	 */
	mode: 'change' | 'renew' = this.router.url.includes('change') ? 'change' : 'renew';

	/**
	 * Payment method
	 */
	paymentMethod: PaymentMethod = 'stripe';

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		businessName: '',
		taxCode: '',
		partitaIva: '',
		address: '',
		city: '',
		province: '',
		country: '',
		postalCode: '',
		eBillingEnabled: false,
		pec: '',
		uniqueCode: '',
		stripePaymentMethodId: null,
		subscriptionId: '',
	});

	subscriptions: BackendSubscription[] = [];

	selectedSubscription?: BackendSubscription;
	currentSubscription?: GymSubscription;
	futureSubscription?: GymSubscription;

	/**
	 * Expose moment
	 */
	moment = moment;

	constructor(
		private fb: FormBuilder,
		private toast: ToastService,
		private loader: LoaderService,
		public languageService: LanguageService,
		private translate: TranslateService,
		private ngxStripeService: NgxStripeService,
		private stripeService: StripeService,
		private gymService: GymService,
		private router: Router,
		private confirmation: ConfirmationService,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {

		/**
		 * Load languages
		 */
		this.languages = this.languageService.getAvailableLanguages();

		/**
		 * Fetch the gym
		 */
		this.fetchGym();

		/**
		 * Get and set stripe payment methods
		 */
		this.fetchStripePaymentMethods();

		/**
		 * Get all available subcriptions for the gym
		 */
		this.fetchAvailableSubscriptions();

		/**
		 * Get the current gym subscription
		 */
		this.fetchCurrentGymSubscription();

		/**
		 * Get the future gym subscription
		 */
		this.fetchFutureGymSubscription();

		/**
		 * On subscription change
		 */
		this.form.get('subscriptionId')?.valueChanges.subscribe({
			next: (subscriptionId: string) => {

				/**
				 * Set for display
				 */
				this.selectedSubscription = this.subscriptions.find(tmp => tmp.id === parseInt(subscriptionId, 10));

				/**
				 * Reset payment method
				 */
				this.paymentMethod = 'stripe';
			}
		});
	}

	/**
	 * Change subscription
	 */
	async submit(event: Event): Promise<void> {
		event.preventDefault();

		/**
		 * Confirm popup
		 */
		const changeConfirm = (): Promise<void> => new Promise((resolve: () => void, reject: () => void) => {
			this.confirmation.open({
				title: this.translate.instant('CAMBIO_ABBONAMENTO'),
				message: this.translate.instant('CAMBIO_ABBONAMENTO_DESCRIPTION'),
				actions: [
					{
						title: this.translate.instant('CONTINUA'),
						type: ConfirmationButtonTypes.Danger,
						callback: () => {
							resolve();
						}
					},
					{
						type: ConfirmationButtonTypes.Default,
						title: this.translate.instant('GENERIC.CANCEL'),
						callback: () => {
							reject();
						},
					}
				]
			});
		});

		/**
		 * Confirm popup
		 */
		const automaticPaymentConfirm = (): Promise<void> => new Promise((resolve: () => void, reject: () => void) => {
			this.confirmation.open({
				title: this.translate.instant('CONTINUIT_AUTOMATICA'),
				message: this.translate.instant('CONTINUITA_AUTOMATICA_DESCRIPTION'),
				actions: [
					{
						title: this.translate.instant('CONTINUA'),
						type: ConfirmationButtonTypes.Danger,
						callback: () => {
							resolve();
						}
					},
					{
						type: ConfirmationButtonTypes.Default,
						title: this.translate.instant('GENERIC.CANCEL'),
						callback: () => {
							reject();
						},
					}
				]
			});
		});

		/**
		 * Confirm popup
		 */
		const removeFutureConfirm = (): Promise<void> => new Promise((resolve: () => void, reject: () => void) => {
			this.confirmation.open({
				title: this.translate.instant('CANCELLAZIONE_ABBONAMENTO_FUTURO'),
				message: this.translate.instant('CANCELLAZIONE_ABBONAMENTO_FUTURO_DESCRIPTION'),
				actions: [
					{
						title: this.translate.instant('CONTINUA'),
						type: ConfirmationButtonTypes.Danger,
						callback: () => {
							resolve();
						}
					},
					{
						type: ConfirmationButtonTypes.Default,
						title: this.translate.instant('GENERIC.CANCEL'),
						callback: () => {
							reject();
						},
					}
				]
			});
		});

		/**
		 * If we're in change mode
		 * And he has at least one subscription even if it's in the future
		 * Warn the user of the consequences of the next action
		 */
		if (this.mode === 'change' && (this.currentSubscription || this.futureSubscription)) {
			try {
				await changeConfirm();
			} catch (error) {
				return;
			}
		}

		/**
		 * If the selected subscription has not infinite installments
		 * Warn the user of the automatic standard subscription on expiry
		 */
		if (this.selectedSubscription?.installments !== null) {
			try {
				await automaticPaymentConfirm();
			} catch (error) {
				return;
			}
		}

		/**
		 * If we are on renew mode and there's a future subscription in schedule
		 */
		if (this.mode === 'renew' && this.futureSubscription) {
			try {
				await removeFutureConfirm();
			} catch (error) {
				return;
			}
		}

		/**
		 * If it's a new stripe payment method
		 */
		if (this.form.get('stripePaymentMethodId')?.value === null && this.paymentMethod === 'stripe') {

			/**
			 * Create the payment method
			 */
			try {
				this.loader.show();
				const result = await firstValueFrom(this.ngxStripeService.createPaymentMethod({
					type: 'card',
					card: this.card!.element
				}));
				this.loader.hide();

				/**
				 * If error
				 */
				if (result?.error) {
					this.toast.error(result.error.message || this.translate.instant('ERRORS.UNKNOWN_ERROR'));
					console.error(result.error);
					return;
				}

				/**
				 * Set the payment method ID to the form
				 */
				this.form.patchValue({ stripePaymentMethodId: result?.paymentMethod?.id });
			} catch (error) {
				this.loader.hide();
				this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
				console.error(error);
			}
		}

		/**
		 * Switch between methods depending on what the user has chosen
		 */
		if (this.paymentMethod === 'stripe' && this.mode === 'change') {

			/**
			 * Change the current gym subcription
			 */
			this.changeGymSubscription();
		} else if (this.paymentMethod === 'stripe' && this.mode === 'renew') {

			/**
			 * Schedule a new gym subscription when the current one ends
			 */
			this.scheduleGymSubscription();
		} else if (this.paymentMethod !== 'stripe') {

			/**
			 * Request to us a new gym subscription (like a contact us form basically)
			 */
			this.requestGymSubscription();
		}
	}

	/**
	 * Change the current gym subcription
	 */
	changeGymSubscription(): void {
		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Final success function
		 */
		const success = (): void => {
			this.toast.show(this.translate.instant('IL_TUO_ABBONAMENTO_STATO_CAMBIATO_CON_SUCCESSO_SARAI_REINDIRIZZATO_A_BREVE_ALLA_TUA_DASHBAORD'), 10000);
			setTimeout(() => {
				this.router.navigate(['/', 'dashboard']);
			}, 3000);
		};

		this.loader.show();
		this.gymService.changeGymSubscription(formData)
			.then(() => {
				this.loader.hide();
				success();
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.Validation:
						for (const key in error.error.validation) {
							if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
								this.form.get(key)?.setErrors({ required: true });
								this.toast.error(this.translate.instant('ERRORS.VALIDATION_ERROR'));
							}
						}
						break;

					/**
					 * Handle errors like insuficient funds and stuff like that
					 */
					case Errors.PaymentFailed:
						if (error.error.message) {
							this.toast.error(error.error.message);
							break;
						}

					/**
					 * Handle secure card action
					 */
					case Errors.ThreeDSConfirmation:
						firstValueFrom(this.ngxStripeService.handleCardAction(error.error.client_secret))
							.then((result) => {
								if (result?.error) {
									this.toast.error(this.translate.instant('IL_PAGAMENTO_STATO_CANCELLATO'));
									console.error(result.error);
								} else if (result?.paymentIntent?.id) {

									/**
									 * Confirm the payment
									 */
									this.loader.show();
									this.stripeService.confirmPaymentIntent(result.paymentIntent.id, 'change')
										.then(() => {
											this.loader.hide();
											success();
										})
										.catch(error => {
											this.loader.hide();
											this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
											console.error(error);
										});
								}
							});
						break;
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Schedule a new gym subscription when the current one ends
	 */
	scheduleGymSubscription(): void {
		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		this.loader.show();
		this.gymService.scheduleGymSubscription(formData)
			.then(() => {
				this.loader.hide();
				this.toast.show(this.translate.instant('SCHEDULE_GYM_SUB_SUCCESS'), 10000);
				setTimeout(() => {
					this.router.navigate(['/', 'dashboard']);
				}, 3000);
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.Validation:
						for (const key in error.error.validation) {
							if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
								this.form.get(key)?.setErrors({ required: true });
								this.toast.error(this.translate.instant('ERRORS.VALIDATION_ERROR'));
							}
						}
						break;

					/**
					 * If the renew is not allowed go back
					 */
					case Errors.NotAdmitted:
						this.toast.error(this.translate.instant('IL_RINNOVO_NON_CONSENTITO_AL_MOMENTO'), 10000);
						break;

					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Request to us a new gym subscription (like a contact us form basically)
	 */
	requestGymSubscription(): void {
		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		this.loader.show();
		this.gymService.requestGymSubscription(formData)
			.then(() => {
				this.loader.hide();
				this.toast.show(this.translate.instant('REQUEST_GYM_SUB_SUCCESS'), 10000);
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.Validation:
						for (const key in error.error.validation) {
							if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
								this.form.get(key)?.setErrors({ required: true });
								this.toast.error(this.translate.instant('ERRORS.VALIDATION_ERROR'));
							}
						}
						break;

					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	setLanguage(val: string): void {
		this.languageService.setLanguage(val);
	}

	/**
	 * Get the current gym subscription
	 */
	fetchCurrentGymSubscription(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.loader.show();
			this.gymService.getCurrentGymSubscription()
				.then(gymSubscriprion => {
					this.loader.hide();

					/**
					 * Set for visualization
					 */
					this.currentSubscription = gymSubscriprion;
					resolve();
				})
				.catch(error => {
					this.loader.hide();
					reject();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						default:
							this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
							console.error(error);
							break;
					}
				});
		});
	}

	/**
	 * Get the future gym subscription
	 */
	fetchFutureGymSubscription(): void {
		this.loader.show();
		this.gymService.getFutureGymSubscription()
			.then(gymSubscriprion => {
				this.loader.hide();

				/**
				 * Set for visualization
				 */
				this.futureSubscription = gymSubscriprion;
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Delete payment method
	 */
	deleteStripePaymentMethod(paymentMethodId: string): void {
		if (window.confirm(this.translate.instant('SEI_SICURO_DI_VOLER_ELIMINARE_QUESTA_CARTA'))) {
			this.loader.show();
			this.stripeService.removeStripePaymentMethod(paymentMethodId)
				.then(() => {

					/**
					 * Redirect
					 */
					this.loader.hide();
					this.toast.show(this.translate.instant('CARTA_RIMMOSSA_CON_SUCCESSO'));

					/**
					 * Refresh list
					 */
					this.fetchStripePaymentMethods();
				})
				.catch(error => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.UserNotInGym: break;

						default:
							this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
							console.error(error);
							break;
					}
				});
		}
	}

	/**
	 * Get and set stripe payment methods
	 */
	fetchStripePaymentMethods(): void {
		this.loader.show();
		this.stripeService.getStripePaymentMethods()
			.then(payment_methods => {
				this.stripePaymentMethods = payment_methods;

				/**
				 * Set first payment method default
				 */
				this.form.get('stripePaymentMethodId')?.setValue(payment_methods[0]?.id || null);
				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Get gym
	 */
	fetchGym(): void {
		this.loader.show();
		this.gymService.get()
			.then(gym => {

				/**
				 * Fill the form
				 */
				this.form.patchValue({
					businessName: gym.company,
					taxCode: gym.tax_code,
					partitaIva: gym.partita_iva,
					eBillingEnabled: gym.e_billing_enabled,
					address: gym.headquarters_address,
					city: gym.headquarters_city,
					province: gym.headquarters_province,
					country: gym.headquarters_country,
					postalCode: gym.headquarters_postal_code,
					pec: gym.pec,
					uniqueCode: gym.unique_code,
				});
				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Get gym's available subscriptions
	 */
	fetchAvailableSubscriptions(): void {
		this.loader.show();
		this.gymService.getAvailableSubscriptions()
			.then(subscriptions => {
				this.subscriptions = subscriptions;
				if (subscriptions[0]) {

					/**
					 * Set the first in the form
					 */
					this.form.patchValue({
						subscriptionId: subscriptions[0].id
					});
				}

				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Set payment method
	 */
	setPaymentMethod(event: any): void {
		this.paymentMethod = event.target.value as PaymentMethod;
	}

	/**
	 * Open promo code dialog
	 */
	openPromoCodeDialog(): void {

		/**
		 * Open and listen for dialog response
		 */
		const dialogRef = this.dialog.open(ApplyPromoCodeDialog);
		dialogRef.afterClosed().subscribe(success => {

			/**
			 * On success, reload available subscriptions
			 */
			if (success) {
				this.toast.show(this.translate.instant('PROMO_DIALOG'));
				this.fetchAvailableSubscriptions();
			}
		});
	}
}
