import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

	/**
	 * BehaviorSubject Object
	 */
	private status = new BehaviorSubject<boolean>(false);

	constructor() { }

	/**
	 * Set Loader Status to Open
	 */
	show(): void {
		this.status.next(true);
	}

	/**
	 * Set Loader Status to Close
	 */
	hide(): void {
		this.status.next(false);
	}

	/**
	 * Subscribe to status change
	 *
	 * @param callback - function executed on status change
	 */
	subscribe(callback: (status: boolean) => void): Subscription {
		return this.status.subscribe((next: boolean) => callback(next));
	}
}
