<div class="app-body" [appBackgroundImage]="'assets/img/bg_login.png'">

	<main class="language">
		<ul class="nav navbar-nav ml-auto justify-content-end">
			<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf='languages'>
				<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
					(click)="false">
					<span class='flag-icon fa-2x'
						[ngClass]='languageService.getLanguage() !== "en" ? ("flag-icon-" + languageService.getLanguage()) : "flag-icon-gb"'>
					</span>
				</a>
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<ng-container *ngFor='let obj of languages | keyvalue'>
						<a class="dropdown-item" (click)="setLanguage(obj.key)">
							<span class='flag-icon'
								[ngClass]='obj.key !== "en" ? ("flag-icon-" + obj.key) : "flag-icon-gb"'></span>
							{{ obj.value }}
						</a>
					</ng-container>
				</div>
			</li>
		</ul>
	</main>

	<main class="main d-flex">
		<div class="container">
			<div class="row justify-content-md-center">
				<div class="img-logo col-md-9 p-5">
					<img src="assets/img/logo_bianco.png">
				</div>
			</div>
			<div class="row justify-content-md-center">
				<div class="login col-md-5 p-0">
					<div class="card-group">
						<div class="card p-2">
							<div class="card-body">
								<form [formGroup]="form" (submit)='login($event)'>
									<div class="box-top">
										<h1>{{ "LOGIN.TITLE"|translate }}</h1>
										<p class="text-muted">{{ "LOGIN.SUBTITLE"|translate }}</p>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text"><i class="icon-user"></i></span>
											</div>
											<input type="text" class="form-control"
												[ngClass]="{ 'is-invalid': !!form.get('login')?.errors }"
												placeholder="{{ 'EMAIL'|translate }}" formControlName='login'
												autocomplete="email">
										</div>
										<div class="input-group mb-4">
											<div class="input-group-prepend">
												<span class="input-group-text"><i class="icon-lock"></i></span>
											</div>
											<input type="password" class="form-control"
												[ngClass]="{ 'is-invalid': !!form.get('password')?.errors }"
												placeholder="{{ 'PASSWORD'|translate }}" formControlName='password'
												autocomplete="current-password">
										</div>
									</div>
									<div class="box-bottom">
										<input type="submit" class="btn btn-primary px-4"
											value="{{ 'LOGIN.TITLE'|translate }}">
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div class="register col-md-4 p-0">
					<div class="card-group">
						<div class="card p-2">
							<div class="card-body">
								<div class="box-bottom">
									<h1>{{ "REGISTER.TITLE"|translate }}</h1>
									<p class="text-muted">
										{{ 'UNISCITI_ALLA_RETE_DI_CROSS_IN_IN_COMPLETA_AUTONOMIA'|translate }} <br>
										{{
										'CREA_IL_TUO_PROFILO_E_INZIA_A_USARE_CROSS_IN_PER_GESTIRE_IL_TUO_BIOX'|translate
										}}
									</p>
									<p class="text-muted">
										{{ 'PER_TE_20_GIORNI_DI_PROVA_GRATUITA'|translate }}
									</p>
									<p class="text-muted">
										{{ 'AL_TERMINE_DELLA_PROVA_CONTATTACI_SU'|translate }}
										<a href="mailto:info@cross-in.com">info@cross-in.com</a>
										{{
										'PER_CONOSCERE_EVENTUALI_PROMOZIONI_IN_ESSERE_SIAMO_SICURI_CHE_NON_SE_RIMARRAI_DELUSO'
										}}
									</p>
								</div>
								<div class="box-bottom">
									<div class="row">
										<input [routerLink]="['/', 'register']" class="btn btn-primary"
											value="{{ 'REGISTRATI'|translate }}">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>