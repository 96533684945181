<ng-container *ngIf="gymSubscription">
	<h3 class="m-0">
		<i class="bi bi-exclamation-triangle-fill text-danger mr-1"></i>
		{{ 'ABBONAMENTO_CROSS_IN_SCADUTO'|translate }}
	</h3>
	<p class="my-4">
		{{ 'IL_TUO_ABBONAMENTO_CROSS_IN_SCADUTO_IL'|translate }} {{ gymSubscription.valid_to }}, <br>
		{{ 'HAI'|translate }} {{ gracePeriodRemainigDays }} {{ 'GIORNI_PER_CAMBIARE_ABBONAMENTO'|translate }}
		<ng-container *ngIf="gymSubscription.has_expired_invoices">
			{{ 'O_RINNOVARE_L_ABBONAMENTO_CORRENTE'|translate }}
		</ng-container>
	</p>
	<div class="d-flex justify-content-between">
		<button class="btn btn-link" mat-dialog-close>
			{{ 'CHIUDI'|translate }}
		</button>
		<div class="d-flex justify-content-end">
			<button mat-dialog-close class="btn btn-info mr-2" *ngIf="gymSubscription.has_expired_invoices"
				[routerLink]="['/', 'pay-invoices']">
				{{ 'RINNOVA'|translate }}
			</button>
			<button mat-dialog-close class="btn btn-info" [routerLink]="['/', 'change-subscription']">
				{{ 'CAMBIA'|translate }}
			</button>
		</div>
	</div>
</ng-container>