import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ConfirmationButtonTypes } from 'src/app/enums/confirmation-button-types.enum';
import { Workout } from 'src/app/interfaces/workout';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WorkoutService } from 'src/app/services/workout/workout.service';

enum WodVisionCommands {
	ALIGN_LEFT,
	ALIGN_CENTER,
	ALIGN_RIGHT,
	FS_UP,
	FS_DOWN
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'dialog-wod-vision',
	templateUrl: './wod-vision.dialog.html',
	styleUrls: ['./wod-vision.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class WodVisionDialog implements OnInit {

	/**
	 * Workout: when we are on update
	 */
	workout?: Workout;

	/**
	 * Params
	 */
	params: {[key: string]: any} = {};

	/**
	 * CSS Classes enabled for mixing
	 */
	cssClassesEnabled: number[] = [];

	/**
	 * Import wod vision commands enum
	 */
	wodVisionCommands = WodVisionCommands;

	/**
	 * Import moment
	 */
	moment = moment;

	/**
	 * Fullscreen boolean
	 */
	isFullscreen = false;

	constructor(
		public loader: LoaderService,
		public translate: TranslateService,
		public toast: ToastService,
		private workoutsService: WorkoutService,
		private confirmationService: ConfirmationService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {

		this.params = this.route.snapshot.params;

		/**
		 * On fullscreen change
		 */
		document.addEventListener('fullscreenchange',(val) => {
			this.isFullscreen = !!document.fullscreenElement;
		});

		/**
		 * Get the workout
		 */
		this.loader.show();
		this.workoutsService.getByDiscipline(this.params.id, this.params.date)
			.then((workout: Workout) => {

				/**
				 * No workout found, so insert it is
				 */
				if (!workout) {
					this.loader.hide();
					this.confirmationService.open({
						title: 'Error',
						message: this.translate.instant('WVISION.MISSING_WORKOUT',{
							date: moment(this.params.date,'YYYY-MM-DD').format('DD/MM/YYYY')
						}),
						actions: [
							{
								type: ConfirmationButtonTypes.Default,
								title: 'OK',
								callback: () => window.close()
							},
						]
					});
					return;
				}

				this.workout = workout;

				this.loader.hide();
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.confirmationService.open({
							title: 'Error',
							message: this.translate.instant('ERRORS.EXTRACTION_WORKOUT'),
							actions: [
								{
									type: ConfirmationButtonTypes.Default,
									title: 'OK',
									callback: () => window.close()
								},
							]
						});
						break;
				}
			});

	}

	public execCSSCommand(commandType: WodVisionCommands): void{

		switch(commandType){

			case WodVisionCommands.ALIGN_LEFT:
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_LEFT)){return;}

				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_CENTER)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_CENTER),
						1
					);
				}
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_RIGHT)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_RIGHT),
						1
					);
				}

				this.cssClassesEnabled.push(WodVisionCommands.ALIGN_LEFT);
				break;

			case WodVisionCommands.ALIGN_CENTER:
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_CENTER)){return;}

				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_LEFT)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_LEFT),
						1
					);
				}
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_RIGHT)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_RIGHT),
						1
					);
				}

				this.cssClassesEnabled.push(WodVisionCommands.ALIGN_CENTER);
				break;

			case WodVisionCommands.ALIGN_RIGHT:
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_RIGHT)){return;}

				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_CENTER)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_CENTER),
						1
					);
				}
				if(this.cssClassesEnabled.includes(WodVisionCommands.ALIGN_LEFT)){
					this.cssClassesEnabled.splice(
						this.cssClassesEnabled.indexOf(WodVisionCommands.ALIGN_LEFT),
						1
					);
				}

				this.cssClassesEnabled.push(WodVisionCommands.ALIGN_RIGHT);
				break;

			case WodVisionCommands.FS_UP:
			case WodVisionCommands.FS_DOWN:
				// Get the root element
				const baseElement = document.querySelector(':root') || document.documentElement;
				const root = getComputedStyle(baseElement);

				/**
				 * Get every property
				 */
				let lh = parseInt(root.getPropertyValue('--wvision-line-height-card-body').replace('px',''), 10);
				let body = parseInt(root.getPropertyValue('--wvision-font-size-card-body').replace('px',''), 10);
				let title = parseInt(root.getPropertyValue('--wvision-font-size-card-title').replace('px',''), 10);

				/**
				 * Sum 2 to everything
				 */
				if(commandType === WodVisionCommands.FS_UP){
					lh += 2;
					body += 2;
					title += 2;
				} else {
					lh -= 2;
					body -= 2;
					title -= 2;
				}

				/**
				 * Set everything to css
				 */
				document.documentElement.style.setProperty('--wvision-line-height-card-body', lh + 'px');
				document.documentElement.style.setProperty('--wvision-font-size-card-body', body + 'px');
				document.documentElement.style.setProperty('--wvision-font-size-card-title', title + 'px');

				break;

		}

	}

	public changeFullscreen(): void{

		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}

	}

	/**
	 * Correctly get window-management
	 */
	private async getWindowManagementPermissionState(): Promise<string> {
		let state;

		// The new permission name.
		try {
			({ state } = await navigator.permissions.query({
				name: 'window-management',
			} as any));
		} catch (err: any) {
			console.log('ERR', err);
			if (err.name !== 'TypeError') {
				return `${err.name}: ${err.message}`;
			}
			// The old permission name.
			try {
				({ state } = await navigator.permissions.query({
					name: 'window-placement',
				} as any));
			} catch (otherErr: any) {
				if (otherErr.name === 'TypeError') {
					return 'Window management not supported.';
				}
				return `${otherErr.name}: ${otherErr.message}`;
			}
		}

		if(state === 'prompt'){
			await (window as any).getScreenDetails();
			return await this.getWindowManagementPermissionState();
		}

		return state;
	}
}
