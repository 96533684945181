import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	/**
	 *	Status
	 */
	show = false;

	/**
	 * Request counter
	 */
	private counter = 0;

	constructor(private loader: LoaderService) { }

	ngOnInit(): void {
		this.loader.subscribe((show: boolean) => {

			if (show) {
				this.counter++;
			} else {
				if (this.counter > 0) {
					this.counter--;
				}
			}

			/**
			 * Show hide the loader
			 */
			this.show = this.counter > 0;

		});
	}
}
