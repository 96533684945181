import { Injectable } from '@angular/core';
// eslint-disable-next-line max-len
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin/admin.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

	constructor(
		private router: Router,
		private admin: AdminService,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		/**
		 * Check admin is logged in
		 */
		if ( !this.admin.isAuthenticated()) {

			/**
			 * Redirect to path
			 */
			return this.router.parseUrl('/');

		}else if ( state.url === '/' ){

			return this.router.parseUrl('/dashboard');

		}

		return true;

	}
	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(next, state);
	}
}
