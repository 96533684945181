
import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageTypes } from '../enums/local-storage-types.enum';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { DecimalPipe } from './decimal.pipe';

@Pipe({
	name:'money'
})
export class MoneyPipe implements PipeTransform {

	constructor(
		private localStorageService: LocalStorageService
	) {}

	transform(value: number): string {
		const gymCurrency = this.localStorageService.getItem(LocalStorageTypes.GymCurrency);
		const pipe = new DecimalPipe();
		return pipe.transform(value) + ' ' + (gymCurrency || '€');
	}

}
