import { Injectable } from '@angular/core';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { PreferencesTypes } from 'src/app/enums/preferences-types.enum';
import { Requests } from 'src/app/enums/requests';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
/* eslint-disable @typescript-eslint/dot-notation */

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {

	/**
	 * Three states
	 * - false : To get from the internet
	 * - promise : pending request, stop other requests
	 * - true : request made, do not go forward
	 */
	private promiseInit: Promise<{[key: string]: any}>|boolean = false;

	private initRequestPending = false;
	private setRequestPending = false;

	constructor(
		private http: HttpService,
		private localStorage: LocalStorageService
	) { }

	public init(): Promise<{[key: string]: any}>{

		const promise = new Promise((resolve: (value: {[key: string]: any}) => void ,reject) => {
			if(this.initRequestPending || this.promiseInit === true){
				resolve(this.initRequestPending ? {} : this.localStorage.getItem(LocalStorageTypes.Preferences));
				return;
			}
			this.initRequestPending = true;
			this.http.send(Requests.getPreferences).then((res: {[key: string]: any}) => {
				this.localStorage.setItem(LocalStorageTypes.Preferences, res.length ? {} : res);
				resolve(res.length ? {} : res);
				this.promiseInit = true;
			}).finally(() => this.initRequestPending = false);
		});
		this.promiseInit = promise;

		return promise;

	}

	public get(type: PreferencesTypes, tableId?: string): Promise<{[key: string]: any} | string | number | null>{

		return new Promise((resolve,reject) => {
			const exec = (): void => {
				const prefs = this.localStorage.getItem(LocalStorageTypes.Preferences);

				/**
				 * Get directly tables preference or the main type
				 */
				if(type === PreferencesTypes.Tables){

					if(tableId){
						resolve((prefs[type] && prefs[type][tableId]) ? prefs[type][tableId] : null);
					} else {
						resolve(null);
					}
				} else {
					resolve(prefs[type] || null);
				}
			};

			if(this.promiseInit !== true && this.promiseInit !== false){
				this.promiseInit.then(() => {
					exec();
				});
			} else {
				exec();
			}

		});

	}

	public set(type: PreferencesTypes, data: {[key: string]: any}, tableId?: string): Promise<{[key: string]: any} | string | number | null | void>{

		return new Promise(async (resolve,reject) => {
			if(this.promiseInit !== true && this.promiseInit !== false){
				await this.promiseInit;
			}
			let prefs = this.localStorage.getItem(LocalStorageTypes.Preferences);

			/**
			 * Fix for eventually array problems
			 */
			if(Object.prototype.hasOwnProperty.call(prefs,'length')){
				const newObj: {[key: string]: any} = {};
				for (const key in PreferencesTypes) {
					if (Object.prototype.hasOwnProperty.call(PreferencesTypes, key)) {
						const element = prefs[key];
						newObj[key] = element;
					}
				}
				prefs = newObj;
			}

			if(type === PreferencesTypes.Tables){
				if(tableId){
					if(!prefs[type]) {prefs[type] = {};}
					prefs[type][tableId] = data;
				} else {
					console.error('TABLE ID MISSING!!');
					return;
				}
			} else {
				prefs[type] = data;
			}

			console.log(prefs,type,tableId);

			if(this.setRequestPending){
				resolve(prefs);
				return;
			}
			this.setRequestPending = true;
			this.http.send(Requests.setPreferences,{
				body: {
					preferences: prefs
				}
			}).then(() => resolve(prefs))
			  .finally(() => this.setRequestPending = false);

			this.localStorage.setItem(LocalStorageTypes.Preferences, prefs);
		});
	}

}
