import {
	HttpErrorResponse, HttpHandler,
	HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {
	Injectable, Injector
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Observable, throwError
} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from '../enums/local-storage-types.enum';
import { AdminService } from '../services/admin/admin.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class GymInterceptor implements HttpInterceptor {

	constructor(
		private localStorage: LocalStorageService,
		private adminService: AdminService,
		private toast: ToastService,
		private injector: Injector
	) { }

	/**
	 * Update header HTTP call
	 *
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<any> {

		/**
		 * HttpHeader object immutable - copy values
		 */
		const headerSettings: {
			[name: string]: string | string[];
		} = {};

		/**
		 * Set keys
		 */
		for (const key of request.headers.keys()) {
			headerSettings[key] = request.headers.get(key) as string;
		}

		/**
		 * Set GymToken if exist
		 */
		if (!request.url.includes('https://weblate.camacrea.it')) {
			const gymCode = this.localStorage.getItem(LocalStorageTypes.GymCode);
			if (gymCode != null) {
				headerSettings['Gym-Code'] = gymCode;
			}
		}

		/**
		 * Add token code to header
		 */
		const newHeader = new HttpHeaders(headerSettings);
		const req = request.clone({
			headers: newHeader
		});

		/**
		 * Next request
		 */
		return next.handle(req)

			/**
			 * Check if the request has no gym related errors
			 */
			.pipe(
				// No retry
				retry(0),
				catchError((error: HttpErrorResponse) => {

					/**
					 * Translator must be injected in runtime in interceptors
					 */
					const translate = this.injector.get(TranslateService);

					switch (error?.error?.code) {
						case Errors.LackGymCode:
							this.adminService.logout();
							this.toast.error(translate.instant('ERRORS.GYM_CODE_UNKNOWN'));
							break;

						case Errors.GymNotFound:
							this.adminService.logout();
							this.toast.error(translate.instant('ERRORS.GYM_UNKNOWN'));
							break;
					}

					return throwError(() => error);
				})
			);
	}
}
