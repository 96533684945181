import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FetchPaginatedData, TablePaginator, PaginatorFetchParams } from './table.component';

export class TableDataSource<T> implements DataSource<T> {
	/**
	 * Data
	 */
	private dataSubject = new BehaviorSubject<T[]>([]);

	constructor(private fetchPaginatedData: FetchPaginatedData<T>) {}

	connect(collectionViewer: CollectionViewer): Observable<T[]> {
		return this.dataSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.dataSubject.complete();
	}

	async fetch(paginatorParams: PaginatorFetchParams): Promise<TablePaginator<T> | null> {
		const paginator = await this.fetchPaginatedData(paginatorParams);
		this.dataSubject.next(paginator?.data || []);
		return paginator || null;
	}
}
