import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StripeService } from 'ngx-stripe';
import { Errors } from 'src/app/enums/errors.enum';
import { GymService } from 'src/app/services/gym/gym.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-apply-promo-code-dialog',
	templateUrl: './apply-promo-code-dialog.html',
	styleUrls: ['./apply-promo-code-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ApplyPromoCodeDialog {

	promoCodeControl = new FormControl('');

	constructor(
		public dialogRef: MatDialogRef<ApplyPromoCodeDialog>,
		private toast: ToastService,
		private loader: LoaderService,
		private translate: TranslateService,
		private gymService: GymService,
	) {

		/**
		 * Backdrop click
		 * Returns success false
		 */
		const subscription = dialogRef.backdropClick().subscribe({
			next: () => {
				subscription.unsubscribe();
				dialogRef.close(false);
			}
		});
	}

	/**
	 * Apply the promo code
	 */
	apply(): void {
		if (this.promoCodeControl.value === '') { return; }

		this.loader.show();
		this.gymService.applyPromoCode(this.promoCodeControl.value)
			.then(() => {

				/**
				 * Close and return success true
				 */
				this.dialogRef.close(true);

				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.ModelNotFound:
						this.toast.error('Il codice inserito non è valido');
						break;
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}
}
