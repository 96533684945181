<main class="register" [appBackgroundImage]="'assets/img/bg_login.png'">
	<div class="language">
		<ul class="nav navbar-nav ml-auto justify-content-end">
			<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf='languages'>
				<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
					(click)="false">
					<span class='flag-icon fa-2x'
						[ngClass]='languageService.getLanguage() !== "en" ? ("flag-icon-" + languageService.getLanguage()) : "flag-icon-gb"'>
					</span>
				</a>
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<ng-container *ngFor='let obj of languages | keyvalue'>
						<a class="dropdown-item" (click)="setLanguage(obj.key)">
							<span class='flag-icon'
								[ngClass]='obj.key !== "en" ? ("flag-icon-" + obj.key) : "flag-icon-gb"'></span>
							{{ obj.value }}
						</a>
					</ng-container>
				</div>
			</li>
		</ul>
	</div>

	<div class="main d-flex">
		<div class="container">
			<div class="row justify-content-md-center">
				<div class="img-logo col-md-9 p-5">
					<img src="assets/img/logo_bianco.png">
				</div>
			</div>
			<div class="row justify-content-md-center">
				<div class="col-md-5 p-0">
					<div class="card p-2">
						<form class="card-body d-flex flex-column justify-content-between" [formGroup]="form"
							(submit)='register($event)'>
							<div class="box-top">
								<h1>{{ 'REGISTER.TITLE'|translate }}</h1>
								<p class="text-muted">{{ 'REGISTRATI_CON_IL_TUO_BOX'|translate }}</p>
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="bi bi-building"></i></span>
									</div>
									<input type="text" class="form-control"
										[ngClass]="{ 'is-invalid': !!form.get('name')?.errors }"
										placeholder="{{ 'FORM.NAME'|translate }}" formControlName='name'>
								</div>
								<div class="form-group mb-3">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-user"></i></span>
										</div>
										<input type="text" class="form-control"
											[ngClass]="{ 'is-invalid': !!form.get('email')?.errors }"
											placeholder="{{ 'EMAIL'|translate }}" formControlName='email'>
									</div>
									<small class="text-danger d-block mt-1"
										*ngIf="form.get('email')?.hasError('emailTaken')">
										Questa email è già presente nei nostri sistemi
									</small>
								</div>
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="icon-lock"></i></span>
									</div>
									<input type="password" class="form-control"
										[ngClass]="{ 'is-invalid': !!form.get('password')?.errors }"
										placeholder="{{ 'PASSWORD'|translate }}" formControlName='password'>
								</div>
								<div class="form-group mb-3">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="icon-check"></i></span>
										</div>
										<input type="password" class="form-control"
											[ngClass]="{ 'is-invalid': !!form.get('password')?.errors }"
											placeholder="{{ 'FORM.PASSWORD_CONFIRMATION'|translate }}"
											formControlName='password_confirmation'>
									</div>
									<small class="text-danger d-block mt-1"
										*ngIf="form.get('password')?.hasError('passwordMismatch')">
										{{ 'LE_PASSWORD_NON_COINCIDONO'|translate }}
									</small>
									<small class="text-danger d-block mt-1"
										*ngIf="form.get('password')?.hasError('passwordLength')">
										{{ 'LA_PASSWORD_DEVE_CONTENERE_TRA_I_8_E_I_255_CARATTERI'|translate }}
									</small>
								</div>
								<small class="d-block mb-3 cursor-pointer" [routerLink]="['/', 'login']">
									{{ 'HAI_GI_UN_ACCOUNT_VAI_ALLA_LOGIN'|translate }}
								</small>
							</div>
							<div class="box-bottom">
								<input type="submit" class="btn btn-primary px-4" value="{{ 'REGISTRATI'|translate }}">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>