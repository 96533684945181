import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { AdminService } from 'src/app/services/admin/admin.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		password: null,
		password_confirmation: null,
		code: null
	});

	/**
	 * Errors
	 */
	errors = null;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private adminService: AdminService,
		private toast: ToastService,
		private loader: LoaderService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		const code = this.route.snapshot.params.code;
		this.adminService.checkCode(
			code
		).then(() => {
			this.loader.hide();
			this.form.get('code')?.setValue(code);

		}).catch((error: HttpErrorResponse) => {
			this.loader.hide();

			/**
			 * Error catch
			 */
			switch (error?.error?.code) {
				case Errors.ModelNotFound:
				case Errors.LackPermission:
					this.toast.error(error.error.message);
					break;
				case Errors.Validation:
					this.toast.error(error.error.message);
					this.router.navigateByUrl('/login');
					break;
				default:
					this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
					console.error(error);
					break;
			}
		});
	}

	/**
	 *  Reset Password
	 */
	resetPassword(event: Event): void {
		event.preventDefault();

		this.toast.hide();

		/**
		 * Try Resetting to dashboard
		 */
		this.loader.show();
		this.adminService.changePassword(
			this.form
		).then(() => {
			this.loader.hide();

			/**
			 * Redirect dashboard
			 */
			this.router.navigateByUrl('/thank-you-reset');

			/**
			 * Send toast to notify user about the changed password
			 */
			this.toast.show(this.translate.instant('RESET-PASSWORD.SUCCESS'));

		}).catch((error: HttpErrorResponse) => {
			this.loader.hide();

			/**
			 * Error catch
			 */
			switch (error?.error?.code) {
				case Errors.ModelNotFound:
				case Errors.LackPermission:
					this.toast.error(this.translate.instant('ERRORS.WRONG_PASSWORD'));
					break;
				case Errors.Validation:
					this.toast.error(this.translate.instant('ERRORS.VALIDATION_ERROR'));
					for (const key in error.error.validation) {
						if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
							this.form.get(key)?.setErrors({
								required: true
							});
						}
					}
					break;
				default:
					this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
					console.error(error);
					break;
			}
		});
	}
}
