<div class="w-vision">
	<div class="header">
		<div class="date">
			{{ 'GENERIC.DATE'|translate }}: {{ params.date|date:'dd/MM/yyyy' }}
		</div>
		<div class="toolbar">
			<a class="btn" (click)="execCSSCommand(wodVisionCommands.FS_UP)">
				<span class="material-symbols-outlined">
					text_increase
				</span>
			</a>
			<a class="btn" (click)="execCSSCommand(wodVisionCommands.FS_DOWN)">
				<span class="material-symbols-outlined">
					text_decrease
				</span>
			</a>
			<a class="btn" (click)="execCSSCommand(wodVisionCommands.ALIGN_LEFT)">
				<span class="material-symbols-outlined">
					format_align_left
				</span>
			</a>
			<a class="btn" (click)="execCSSCommand(wodVisionCommands.ALIGN_CENTER)">
				<span class="material-symbols-outlined">
					format_align_center
				</span>
			</a>
			<a class="btn" (click)="execCSSCommand(wodVisionCommands.ALIGN_RIGHT)">
				<span class="material-symbols-outlined">
					format_align_right
				</span>
			</a>
			<a class="btn" (click)="changeFullscreen()">
				<span class="material-symbols-outlined" *ngIf="!isFullscreen">
					fullscreen
				</span>
				<span class="material-symbols-outlined" *ngIf="isFullscreen">
					fullscreen_exit
				</span>
			</a>
		</div>
	</div>
	<div class="wod" [ngClass]="{ 'align-right': cssClassesEnabled.includes(wodVisionCommands.ALIGN_RIGHT), 'align-center': cssClassesEnabled.includes(wodVisionCommands.ALIGN_CENTER), 'align-left': cssClassesEnabled.includes(wodVisionCommands.ALIGN_LEFT)}">
		<div class="card" *ngIf="workout?.warm_up && workout?.warm_up?.description">
			<div class="card-title">{{ 'WORKOUT.WARM_UP'|translate }}</div>
			<div class="card-body">
				<ul>
					<li *ngFor="let exercise of (workout?.warm_up?.description || '').split('\n')">
						<ng-container *ngIf="exercise.trim() === ''"><br></ng-container>
						<ng-container *ngIf="exercise.trim() !== ''">{{ exercise }}</ng-container>
					</li>
				</ul>
			</div>
		</div>
		<div class="card" *ngIf="workout?.skills && workout?.skills?.description">
			<div class="card-title">{{ 'WORKOUT.STRENGTH'|translate }}</div>
			<div class="card-body">
				<ul>
					<li *ngFor="let exercise of (workout?.skills?.description || '').split('\n')">
						<ng-container *ngIf="exercise.trim() === ''"><br></ng-container>
						<ng-container *ngIf="exercise.trim() !== ''">{{ exercise }}</ng-container>
					</li>
				</ul>
			</div>
		</div>
		<div class="card" *ngIf="workout?.wod && workout?.wod?.description">
			<div class="card-title">{{ 'GENERIC.WOD'|translate }}</div>
			<div class="card-body">
				<ul>
					<li *ngFor="let exercise of (workout?.wod?.description || '').split('\n')">
						<ng-container *ngIf="exercise.trim() === ''"><br></ng-container>
						<ng-container *ngIf="exercise.trim() !== ''">{{ exercise }}</ng-container>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="footer">
		<span>Cross In &copy; {{ moment().format('YYYY') }}</span>
		<span>Powered by Camacrea</span>
	</div>
</div>
