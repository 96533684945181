import {
	HttpErrorResponse, HttpEvent, HttpHandler,
	HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Errors } from '../enums/errors.enum';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class ActivatedInterceptor implements HttpInterceptor {

	constructor(
		private toast: ToastService,
		private router: Router,
		private injector: Injector
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// No retry
			retry(0),
			catchError((error: HttpErrorResponse) => {

				/**
				 * Translator must be injected in runtime in interceptors
				 */
				const translate = this.injector.get(TranslateService);

				/**
				 * Gym not activated
				 */
				if (!this.router.url.includes('change-subscription') && error?.error?.code === Errors.NotActivated) {
					this.toast.error(translate.instant('ABBONAMENTO_SCADUTO_O_NON_ATTIVO'));
					this.router.navigate(['/', 'change-subscription']);
				}

				/**
				 * Gym has unpaid invoices
				 */
				if (!this.router.url.includes('pay-invoices') && error?.error?.code === Errors.UnpaidInvoices) {
					this.toast.error(translate.instant('ABBONAMENTO_SCADUTO_O_NON_ATTIVO'));
					this.router.navigate(['/', 'pay-invoices']);
				}

				return throwError(() => error);
			})
		);
	}
}
