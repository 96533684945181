import { NgModule } from '@angular/core';
import { DecimalPipe } from './decimal.pipe';
import { MoneyPipe } from './money.pipe';

@NgModule({
	declarations: [
		DecimalPipe,
		MoneyPipe
	],
	exports: [
		DecimalPipe,
		MoneyPipe
	]
})
export class PipesModule { }
