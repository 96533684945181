import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { Requests } from 'src/app/enums/requests';
import { Admin } from 'src/app/interfaces/admin';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

type LocalStorageData = {
	gym_code: string;
	gym_currency: string;
	token: string;
};

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	constructor(
		private localStorageService: LocalStorageService,
		private router: Router,
		private http: HttpService,
	) { }

	/**
	 * Get login
	 */
	login(user: string, password: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.login, {
				body: {
					login: user,
					password,
				}
			})
				.then((data: LocalStorageData) => {

					/**
					 * Save the Gym Code
					 */
					this.localStorageService.setItem(LocalStorageTypes.GymCode, data.gym_code);

					/**
					 * Save the AuthToken
					 */
					this.localStorageService.setItem(LocalStorageTypes.AuthToken, data.token);

					/**
					 * Save the Gym currency
					 */
					this.localStorageService.setItem(LocalStorageTypes.GymCurrency, data.gym_currency);

					resolve();
				})
				.catch(error => reject(error));
		});
	}

	/**
	 * Get register
	 */
	register(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.register, { body: formData })
				.then(() => resolve())
				.catch(error => reject(error));
		});
	}

	/**
	 * Get logout
	 */
	logout(): void {

		/**
		 * Delete data from LocalStorage
		 */
		this.localStorageService.removeItem(LocalStorageTypes.AuthToken);
		this.localStorageService.removeItem(LocalStorageTypes.GymCode);

		/**
		 * Redirect
		 */
		this.router.navigateByUrl('/');
	}

	/**
	 * Change Password
	 */
	changePassword(form: FormGroup): Promise<void> {
		return new Promise((resolve: (obj: any) => void, reject: (error: HttpErrorResponse) => void) => {
			const code = form.get('code')?.value;
			const arrCodes = code.split('!');

			this.http.send(Requests.resetPassword, {
				body: {
					id: parseInt(arrCodes[0], 10),
					password: form.get('password')?.value,
					password_confirmation: form.get('password_confirmation')?.value,
					code: arrCodes[1]
				}
			})
				.then((data) => resolve(data))
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * Check Code
	 */
	checkCode(code: string): Promise<void> {
		return new Promise((resolve: (obj: any) => void, reject: (error: HttpErrorResponse) => void) => {
			const arrCodes = code.split('!');

			this.http.send(Requests.checkResetPasswordCode, {
				body: {
					id: parseInt(arrCodes[0], 10),
					code: arrCodes[1],
				}
			})
				.then((data) => resolve(data))
				.catch((err: HttpErrorResponse) => reject(err));
		});
	}

	/**
	 * Get admin and gym
	 */
	getWithGym(): Promise<Admin> {
		return new Promise((resolve: (admin: Admin) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getAdminWithGym)
				.then((admin: Admin) => resolve(admin))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Set admin's new langauge
	 */
	setLanguage(lang: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.setAdminLanguage, { body: { lang } })
				.then(() => resolve())
				.catch((error) => reject(error));
		});
	}

	/**
	 * Ritorno il token di accesso dell'utente loggato
	 */
	getAuthToken(): void {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken);
	}

	/**
	 *	Is the admin authenticated
	 */
	isAuthenticated(): boolean {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken) != null;
	}
}
