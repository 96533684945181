<div class="app-body" [appBackgroundImage]="'assets/img/bg_login.png'">
	<main class="main d-flex align-items-center">
		<div class="container">
			<div class="row">
				<div class="col-md-4 mx-auto">
					<div class="card-group">
						<div class="card p-4">
							<div class="card-body">
								<h1>{{ "THANKS-RESET-PASSWORD.TITLE"|translate }}</h1>
								<p class="text-muted">{{ "THANKS-RESET-PASSWORD.SUBTITLE"|translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>
