import {
	HttpEvent, HttpHandler,
	HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	Observable
} from 'rxjs';
import { AdminService } from '../services/admin/admin.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private adminService: AdminService) { }

	/**
	 * Update header HTTP call
	 *
	 */
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		/**
		 * HttpHeader object immutable - copy values
		 */
		const headerSettings: {
			[name: string]: string | string[];
		} = {};

		/**
		 * Set keys
		 */
		for (const key of request.headers.keys()) {
			headerSettings[key] = request.headers.get(key) as string;
		}

		/**
		 * Set AuthToken if exist
		 */
		if (this.adminService.getAuthToken() != null && !request.url.includes('https://weblate.camacrea.it')) {
			headerSettings.Authorization = 'Bearer ' + this.adminService.getAuthToken();
		}

		/**
		 * Add token code to header
		 */
		const newHeader = new HttpHeaders(headerSettings);
		const authReq = request.clone({
			headers: newHeader
		});

		return next.handle(authReq);
	}
}
