import { INavData } from '@coreui/angular';

export interface INavDataConfig extends INavData {
	crossfitEnabled?: boolean;
	children?: INavDataConfig[];
}

// TRADOTTI CON TRANSLATE NEL BASE COMPONENT
export const navItems: INavDataConfig[] = [
	{
		name: 'NAV.DASHBOARD',
		url: ['/', 'dashboard'],
		icon: 'icon-speedometer'
	},
	{
		name: 'NAV.POST',
		url: ['/', 'posts'],
		icon: 'bi bi-easel'
	},
	{
		name: 'NAV.NOTIFICATIONS',
		url: ['/', 'notifications'],
		icon: 'bi bi-bell'
	},
	{
		name: 'PALINSESTO',
		icon: 'bi bi-calendar2-week',
		children: [
			{
				name: 'NAV.LESSONS',
				url: ['/', 'lessons'],
				icon: 'bi bi-calendar2-week'
			},
			{
				name: 'NAV.DISCIPLINES',
				url: ['/', 'disciplines'],
				icon: 'icon-fire'
			},
			{
				name: 'NAV.WORKOUT',
				crossfitEnabled: false,
				url: ['/', 'workout'],
				icon: 'fa fa-heartbeat'
			},
		]
	},
	{
		name: 'NAV.CHECKIN',
		url: ['/', 'checkins'],
		icon: 'bi bi-check2-circle'
	},
	{
		name: 'NAV.USERS',
		url: ['/', 'users'],
		icon: 'bi bi-person'
	},
	{
		name: 'NAV.SALES',
		url: ['/', 'sales'],
		icon: 'bi bi-currency-euro'
	},
	{
		name: 'PROFILE.TITLE',
		icon: 'bi bi-receipt',
		children: [
			{
				name: 'NAV.SUBSCRIPTIONS',
				url: ['/', 'subscriptions'],
				icon: 'bi bi-map'
			},
			{
				name: 'PIANI_DI_AUTORINNOVO',
				url: ['/', 'renewals'],
				icon: 'bi bi-arrow-repeat'
			},
			{
				name: 'PROFILE.TITLE',
				url: ['/', 'user-subscriptions'],
				icon: 'bi bi-calendar-check'
			},
		]
	},
	{
		name: 'NAV.PRODUCTS',
		url: ['/', 'products'],
		icon: 'bi bi-bag'
	},
	{
		name: 'REPORTS',
		icon: 'bi bi-graph-up',
		children: [
			{
				name: 'NAV.CHECKIN',
				url: ['/', 'reports', 'checkins'],
				icon: 'bi bi-check2-circle'
			},
			{
				name: 'USERS.RECORDS',
				url: ['/', 'reports', 'records'],
				crossfitEnabled: false,
				icon: 'bi bi-trophy'
			},
			{
				name: 'NAV.SALES',
				url: ['/', 'reports', 'sales'],
				icon: 'bi bi-currency-euro'
			},
			{
				name: 'NAV.ACCOUNTING',
				url: ['/', 'reports', 'accounting'],
				icon: 'bi bi-book'
			}
		]
	},
	{
		name: 'SUPPORTO',
		url: ['/', 'support'],
		icon: 'bi bi-headset',
	}
];
