<main class="change-subscription" [appBackgroundImage]="'assets/img/bg_login.png'">
	<div class="language">
		<ul class="nav navbar-nav ml-auto justify-content-end">
			<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf='languages'>
				<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
					(click)="false">
					<span class='flag-icon fa-2x'
						[ngClass]='languageService.getLanguage() !== "en" ? ("flag-icon-" + languageService.getLanguage()) : "flag-icon-gb"'>
					</span>
				</a>
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<ng-container *ngFor='let obj of languages | keyvalue'>
						<a class="dropdown-item" (click)="setLanguage(obj.key)">
							<span class='flag-icon'
								[ngClass]='obj.key !== "en" ? ("flag-icon-" + obj.key) : "flag-icon-gb"'></span>
							{{ obj.value }}
						</a>
					</ng-container>
				</div>
			</li>
		</ul>
	</div>

	<div class="main d-flex">
		<div class="container">
			<div class="row justify-content-md-center">
				<div class="img-logo col-md-9 p-5">
					<img src="assets/img/logo_bianco.png">
				</div>
			</div>
			<div class="row justify-content-md-center">
				<div class="col-md-5 p-0 mr-4">
					<div class="card p-2 form-card">
						<form class="card-body d-flex flex-column justify-content-between" [formGroup]="form"
							(submit)='submit($event)'>
							<div class="box-top">
								<h2>
									{{ (mode === 'change' ? 'CAMBIA_ABBONAMENTO' : 'ACCODA_ABBONAMENTO')|translate }}
								</h2>
								<p class="text-muted">
									{{ 'CAMBIA_O_RINNOVA_IL_TUO_PIANO_DI_ABBONAMENTO'|translate }}
								</p>
								<select class="form-control mb-3" formControlName="subscriptionId">
									<option [value]="subscription.id" *ngFor="let subscription of subscriptions">
										{{ subscription.name }} - {{ (subscription.total || 0) | money }}
									</option>
								</select>
								<ng-container *ngIf="selectedSubscription">
									<p class="mb-2 text-muted" *ngIf="mode === 'renew'">
										{{ 'EVENTUALE_DECORRENZA'|translate }}: {{
										moment(currentSubscription?.valid_to,'DD/MM/YYYY').add('day',
										1).format('DD/MM/YYYY')
										}}
									</p>
									<p class="mb-2 text-muted"
										*ngIf="selectedSubscription.installments !== null && selectedSubscription.installments! > 1">
										{{ selectedSubscription.installments }} {{ 'RATE'|translate }}
									</p>
									<p class="mb-2 text-muted" *ngIf="selectedSubscription.installments === 1">
										{{ 'SINGOLO_PAGAMENTO'|translate }}
									</p>
									<p class="mb-2 text-muted">
										<b>Totale:</b> {{ (selectedSubscription.total_with_tax || 0) | money }}
										<i class="bi bi-info-circle-fill ml-1" matTooltip="{{ (selectedSubscription.total || 0) | money }} + {{ selectedSubscription.tax_percentage }}% IVA"></i>
									</p>
									<p class="mb-2 text-muted">{{ selectedSubscription.description }}</p>
									<hr>
								</ng-container>
								<small class="link-primary cursor-pointer mb-3 text-center d-block"
									(click)="openPromoCodeDialog()">
									<u>{{ 'STRIPE.PROMO_TITLE'|translate }}</u>
								</small>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('businessName')?.errors }"
									placeholder="{{ 'RAGIONE_SOCIALE'|translate }}" formControlName='businessName'>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('taxCode')?.errors }"
									placeholder="{{ 'FORM.CF'|translate }}" formControlName='taxCode'>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('partitaIva')?.errors }"
									placeholder="{{ 'PARTITA_IVA'|translate }}" formControlName='partitaIva'>

								<div class="d-flex align-items-center mb-3">
									<label class="switch switch-pill switch-info switch-sm m-0 flex-shrink-0 mr-2">
										<input type="checkbox" class="switch-input" id="eBillingEnabled-input"
											formControlName="eBillingEnabled" />
										<span class="switch-slider"></span>
									</label>
									<label class="m-0 text-muted" for="eBillingEnabled-input">
										{{ 'L_AZIENDA_ABILITAT_ALLA_RICEZIONE_DELLA_FATTURAZIONE_ELETTRONICA'|translate
										}}
									</label>
								</div>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('uniqueCode')?.errors }" placeholder="SDI"
									formControlName='uniqueCode'
									[attr.disabled]="!form.get('eBillingEnabled')?.value || null">
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('pec')?.errors }" placeholder="PEC"
									formControlName='pec' [attr.disabled]="!form.get('eBillingEnabled')?.value || null">

								<label>Sede legale</label>
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('address')?.errors}"
									placeholder="{{ 'FORM.ADDRESS'|translate }}" formControlName="address" type="text">
								<input class="form-control mb-3" [ngClass]="{'is-invalid': !!form.get('city')?.errors}"
									placeholder="{{ 'FORM.CITY'|translate }}" formControlName="city" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('province')?.errors}"
									placeholder="{{ 'PROVINCIA'|translate }}" formControlName="province" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('country')?.errors}"
									placeholder="{{ 'FORM.COUNTRY'|translate }}" formControlName="country" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('postalCode')?.errors}"
									placeholder="{{ 'FORM.CAP'|translate }}" formControlName="postalCode" type="text">
								<select class="form-control mb-3" *ngIf="selectedSubscription?.installments === 1"
									(change)="setPaymentMethod($event)">
									<option value="stripe">
										{{ 'CARTA_DI_CREDITO_STRIPE'|translate }}
									</option>
									<option value="bonifico-bancario">
										{{ 'BONIFICO_BANCARIO_VERRAI_CONTATTATO_DA_VENDITE'|translate }}
									</option>
								</select>

								<!-- Stripe payment methods -->
								<ng-container *ngIf="paymentMethod === 'stripe'">
									<ul class="payment-methods mb-3" *ngIf="stripePaymentMethods.length > 0">
										<li class="item" *ngFor="let stripePaymentMethod of stripePaymentMethods">
											<input type="radio" formControlName="stripePaymentMethodId"
												[id]="stripePaymentMethod.id" [value]="stripePaymentMethod.id">
											<label class="label text-muted" [attr.for]="stripePaymentMethod.id">
												XXXX XXXX XXXX {{ stripePaymentMethod.card?.last4 }}
											</label>
											<i class="bi bi-trash-fill delete"
												(click)="deleteStripePaymentMethod(stripePaymentMethod.id)"></i>
										</li>
										<li class="new">
											<div class="item-container">
												<input type="radio" formControlName="stripePaymentMethodId"
													[value]="null" id="payment-method-new">
												<label class="label text-muted" for="payment-method-new">
													{{ 'NUOVA_CARTA'|translate }}
												</label>
											</div>
											<div class="card-container"
												[hidden]="form.get('stripePaymentMethodId')?.value !== null">
												<ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
											</div>
										</li>
									</ul>
									<div class="mb-3" *ngIf="stripePaymentMethods.length <= 0">
										<ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
									</div>
								</ng-container>
							</div>
							<div class="box-bottom">
								<input type="submit" class="btn btn-primary px-4"
									value="{{ 'GENERIC.SEND'|translate }}">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>