import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INavData } from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import packageJson from 'package.json';
import { GracePeriodDialogComponent } from 'src/app/components/grace-period-dialog/grace-period-dialog.component';
import { Admin } from 'src/app/interfaces/admin';
import { Gym } from 'src/app/interfaces/gym';
import { GymSubscription } from 'src/app/interfaces/gym-subscription';
import { Notification } from 'src/app/interfaces/notification';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ComponentService } from 'src/app/services/component/component.service';
import { GymService } from 'src/app/services/gym/gym.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PreferencesService } from 'src/app/services/preferences/preferences.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { INavDataConfig, navItems } from '../_nav';

@Component({
	selector: 'app-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {

	/**
	 * Logged admin
	 */
	admin?: Admin;

	/**
	 * Current subscription of the gym
	 */
	gymSubscription?: GymSubscription;

	/**
	 * Future subscription
	 */
	futureSubscription?: GymSubscription;

	sidebarMinimized = true;
	navItems?: INavData[];
	languages?: { [key: string]: string };

	/**
	 * Version
	 */
	version = packageJson.version;

	/**
	 * Expose moment
	 */
	moment = moment;

	/**
	 * Admin's notifcations
	 */
	notifications: Notification[] = [];
	notificationsInterval: any;

	/**
	 * Number of new notifications
	 */
	get newNotifications(): number {
		return this.notifications.filter(notification => !notification.seen).length;
	}

	/**
	 * Is the gym in grace period
	 */
	get isGracePeriod(): boolean {

		/**
		 * Get grace period duration dates
		 */
		const validTo = moment(this.gymSubscription?.valid_to, 'DD/MM/YYYY');
		const startDate = validTo.clone().add(1, 'day');
		const endDate = validTo.clone().add(this.gymSubscription?.grace_period_duration, 'days');

		return moment().isBetween(startDate, endDate, 'date', '[]') && !this.futureSubscription;
	}

	/**
	 * Get grace period remainig days
	 */
	get gracePeriodRemainigDays(): number {

		/**
		 * Get grace period duration dates
		 */
		const validTo = moment(this.gymSubscription?.valid_to, 'DD/MM/YYYY');
		const endDate = validTo.clone().add(this.gymSubscription?.grace_period_duration, 'days');

		return endDate.diff(moment(), 'days');
	}

	constructor(
		private adminService: AdminService,
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		public languageService: LanguageService,
		private componentService: ComponentService,
		private notificationsService: NotificationsService,
		private gymService: GymService,
		private preferences: PreferencesService,
		private dialog: MatDialog
	) { }

	toggleMinimize(e: boolean): void {
		this.sidebarMinimized = e;
	}

	ngOnDestroy(): void {
		clearInterval(this.notificationsInterval);
		delete this.notificationsInterval;
	}

	async ngOnInit(): Promise<void> {
		this.loader.show();

		/**
		 * Get admin
		 */
		this.adminService.getWithGym()
			.then(admin => {
				this.admin = admin;

				/**
				 * Set admin language if he has one
				 */
				if (this.admin.lang) {
					this.languageService.setLanguage(this.admin.lang);
				}

				/**
				 * Translate nav items
				 */
				this.gymService.get().then(gym => {
					this.manageNavItems(gym);

					/**
					 * On translation change
					 */
					this.languageService.onLangChange(() => {

						/**
						 * Translate nav items
						 */
						this.manageNavItems(gym);
					});

				});
				/**
				 * Load languages
				 */
				this.languages = this.languageService.getAvailableLanguages();

				/**
				 * Get admin's preferences
				 */
				;

				/**
				 * Start fetching notifications
				 */
				this.startFetchingNotifications();

				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORS.EXTRACTION_USERS'));
						console.error(error);
						break;
				}
			});

		/**
		 * Get current gym subscription
		 */
		Promise.all([this.gymService.getCurrentGymSubscription(),this.gymService.getFutureGymSubscription(),this.preferences.init()])
			.then(obj => {
				this.gymSubscription = obj[0];
				this.futureSubscription = obj[1];

				/**
				 * If we're on grace period show the popup
				 */
				if (this.isGracePeriod) {
					this.showGracePeriodDialog();
				}

				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORS.EXTRACTION_USERS'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Translate nav items
	 */
	async manageNavItems(gym: Gym): Promise<void> {
		const walk = (array: INavDataConfig[]): INavDataConfig[] => array.filter(item => {

			/**
			 * Eliminate those without crossfit permissions
			 */
			if(typeof item.crossfitEnabled !== 'undefined' && item.crossfitEnabled === false && gym.crossfit_enabled === false){
				return false;
			}
			return true;

		}).map((item: INavDataConfig) => {

			/**
			 * Translate name
			 */
			item.name = this.translate.instant(item.name || '');

			/**
			 * Walk through children
			 */
			if (item.children) {
				item.children = walk(item.children);
			}

			return item;
		});

		/**
		 * Refresh DOM
		 */
		delete this.navItems;
		setTimeout(() => {
			this.navItems = walk(JSON.parse(JSON.stringify(navItems)) || []);
		}, 50);
	};

	logout(): void {
		this.adminService.logout();
	}

	/**
	 * Show the grace period dialog
	 */
	showGracePeriodDialog(): void {
		this.dialog.open(GracePeriodDialogComponent, {
			data: this.gymSubscription
		});
	}

	/**
	 * Set lang
	 */
	setLanguage(code: string): void {

		/**
		 * Set admin new language
		 */
		this.loader.show();
		this.adminService.setLanguage(code)
			.then(() => {
				this.loader.hide();

				/**
				 * Set language locally
				 */
				this.languageService.setLanguage(code);
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * On omponent change
	 */
	onComponentChange(component: Component): void {
		this.componentService.next(component);
	}

	/**
	 * Start interval to fetch notifications
	 */
	private startFetchingNotifications(): void {

		/**
		 * It's already on
		 */
		if (this.notificationsInterval) {
			return;
		}

		/**
		 * Fetch function
		 */
		const fetch = (): void => {
			this.notificationsService.mine()
				.then((notifications) => this.notifications = notifications)
				.catch(() => { });
		};

		/**
		 * Start interval to fetch notifications
		 */
		fetch();
		this.notificationsInterval = setInterval(fetch.bind(this), 60000); // every minute
	}
}
