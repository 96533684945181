<ng-template #template>
	<div class="modal-header">
		<h5 class="modal-title" [innerHTML]="data?.title"></h5>
		<figure class="close-icon" data-dismiss="modal">
			<svg-icon src="assets/img/icons/close.svg" [svgStyle]="{ 'fill': 'black' }"></svg-icon>
		</figure>
	</div>
	<div class="modal-body">
		<p class="message" [innerHTML]="data?.message"></p>
	</div>
	<div class="modal-footer">
		<button class="btn" [ngClass]="{
						'btn-danger': action.type === enums.ButtonTypes.Danger,
						'btn-info': action.type === enums.ButtonTypes.Info,
						'btn-success': action.type === enums.ButtonTypes.Success,
						'btn-default': action.type === enums.ButtonTypes.Default
					}" type="button" (click)='onClick(action)' *ngFor="let action of data?.actions">
			{{ action?.title }}
		</button>
	</div>
</ng-template>