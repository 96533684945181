import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StripeAccount } from 'src/app/interfaces/stripe-account';
import { HttpService } from '../http/http.service';
import { Requests } from 'src/app/enums/requests';
import { PaymentMethod } from '@stripe/stripe-js';

@Injectable({
	providedIn: 'root'
})
export class StripeService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Setup account
	 */
	setupAccount(): Promise<string> {
		return this.http.send(Requests.setupStripeAccount);
	}

	/**
	 * Get stripe account details
	 */
	getAccount(): Promise<StripeAccount> {
		return this.http.send(Requests.getStripeAccount);
	}

	/**
	 * Remove stripe payment method
	 */
	removeStripePaymentMethod(paymentMethodId: string): Promise<void> {
		return this.http.send(Requests.removeStripePaymentMethod, { urlParams: { id: paymentMethodId } });
	}

	/**
	 * Get stripe payment methods
	 */
	getStripePaymentMethods(): Promise<PaymentMethod[]> {
		return this.http.send(Requests.getStripePaymentMethods);
	}

	/**
	 * Confirm the payment intent
	 */
	confirmPaymentIntent(paymentIntentId: string, mode: 'change' | 'invoices'): Promise<void> {
		return this.http.send(Requests.confirmStripePaymentIntent, { body: { paymentIntentId, mode } });
	}
}
