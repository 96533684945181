import {
	NgModule
} from '@angular/core';
import {
	RouterModule, Routes
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from './guards/auth.guard';
import { BaseComponent } from './modules/base/base.component';
import { BuySubscriptionComponent } from './modules/buy-subscription/buy-subscription.component';
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';
import { LoginComponent } from './modules/login/login.component';
import { PayInvoicesComponent } from './modules/pay-invoices/pay-invoices.component';
import { RegisterComponent } from './modules/register/register.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { ThankYouResetPasswordComponent } from './modules/thank-you-reset-password/thank-you-reset-password.component';
import { WodVisionDialog } from './modules/wod-vision/wod-vision.dialog';

const routes: Routes = [

	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: '404',
		component: P404Component,
	},
	{
		path: '500',
		component: P500Component,
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'register',
		component: RegisterComponent,
	},
	{
		path: 'renew-subscription',
		component: BuySubscriptionComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'reset-password/:code',
		component: ResetPasswordComponent,
	},
	{
		path: 'thank-you-reset',
		component: ThankYouResetPasswordComponent,
	},
	{
		path: 'change-subscription',
		component: BuySubscriptionComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'pay-invoices',
		component: PayInvoicesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'w-vision/:id/:date',
		component: WodVisionDialog
	},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./modules/base/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'posts',
				loadChildren: () => import('./modules/base/posts/posts.module').then(m => m.PostsModule)
			},
			{
				path: 'notifications',
				loadChildren: () => import('./modules/base/notifications/notifications.module').then(m => m.NotificationsModule)
			},
			{
				path: 'disciplines',
				loadChildren: () => import('./modules/base/disciplines/disciplines.module').then(m => m.DisciplinesModule)
			},
			{
				path: 'lessons',
				loadChildren: () => import('./modules/base/lessons/lessons.module').then(m => m.LessonsModule)
			},
			{
				path: 'workout',
				loadChildren: () => import('./modules/base/workout/workout.module').then(m => m.WorkoutModule)
			},
			{
				path: 'users',
				loadChildren: () => import('./modules/base/users/users.module').then(m => m.UsersModule)
			},
			{
				path: 'sales',
				loadChildren: () => import('./modules/base/sales/sales.module').then(m => m.SalesModule)
			},
			{
				path: 'subscriptions',
				loadChildren: () => import('./modules/base/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
			},
			{
				path: 'user-subscriptions',
				loadChildren: () => import('./modules/base/user-subscriptions/user-subscriptions.module').then(m => m.UserSubscriptionsModule)
			},
			{
				path: 'renewals',
				loadChildren: () => import('./modules/base/renewals/renewals.module').then(m => m.RenewalsModule)
			},
			{
				path: 'checkins',
				loadChildren: () => import('./modules/base/checkins/checkins.module').then(m => m.CheckinsModule)
			},
			{
				path: 'settings',
				loadChildren: () => import('./modules/base/settings/settings.module').then(m => m.SettingsModule)
			},
			{
				path: 'reports/checkins',
				loadChildren: () => import('./modules/base/reports-checkins/reports-checkins.module').then(m => m.ReportsCheckinsModule)
			},
			{
				path: 'reports/records',
				loadChildren: () => import('./modules/base/reports-records/reports-records.module').then(m => m.ReportsRecordsModule)
			},
			{
				path: 'reports/sales',
				loadChildren: () => import('./modules/base/reports-sales/reports-sales.module').then(m => m.ReportsSalesModule)
			},
			{
				path: 'reports/accounting',
				loadChildren: () => import('./modules/base/reports-accounting/reports-accounting.module').then(m => m.ReportsAccountingModule)
			},
			{
				path: 'support',
				loadChildren: () => import('./modules/base/support/support.module').then(m => m.SupportModule)
			},
			{
				path: 'products',
				loadChildren: () => import('./modules/base/products/products.module').then(m => m.ProductsModule)
			},
			{
				path: 'gym-subscription',
				loadChildren: () => import('./modules/base/gym-subscription/gym-subscription.module').then(m => m.GymSubscriptionModule)
			}
		]
	},
	{
		path: '**',
		component: P404Component
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), TranslateModule],
	exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
