
export enum Requests {
	translate,
	getGym,
	updateGym,
	getDelays,
	stopDelay,
	startDelay,
	setupStripeAccount,
	getStripeAccount,
	login,
	register,
	getAdminWithGym,
	resetPassword,
	checkResetPasswordCode,
	setAdminLanguage,
	getPosts,
	createPost,
	getPost,
	updatePost,
	deletePost,
	getNotifications,
	getMyNotifications,
	createNotification,
	getAthletes,
	getAthlete,
	getCoaches,
	getUsers,
	getUser,
	createUser,
	deleteUser,
	updateUser,
	uploadUserDocument,
	validateUserDocument,
	getSchedulers,
	updateScheduler,
	toggleScheduler,
	getScheduler,
	disableScheduler,
	getMembershipFee,
	getSubscriptions,
	getSubscription,
	createSubscription,
	updateSubscription,
	deleteSubscription,
	getSubscriptionTypes,
	getSubscriptionCadences,
	getUserSubscriptions,
	getSales,
	getSale,
	createSale,
	updateSale,
	deleteSale,
	getRenewals,
	deleteRenewal,
	getCurrencies,
	getMeasurementSystems,
	getPaymentMethods,
	getLessons,
	getLessonsByCoach,
	getLesson,
	massCreateLesson,
	massUpdateLesson,
	deleteLesson,
	getLessonCheckins,
	getNextTodayLesson,
	getGroupedByDayForWeekLessons,
	getWorkoutByDiscipline,
	createWorkout,
	updateWorkout,
	deleteWorkout,
	getTodayWorkout,
	getDisciplines,
	getGroupedByDayForWeekDisciplines,
	createDiscipline,
	deleteDiscipline,
	getStatistics,
	getPersonalRecords,
	getPersonalRecordUnits,
	getCheckins,
	checkin,
	checkout,
	cancelLesson,
	restoreLesson,
	getPRBenchmark,
	getPersonalRecordsExercices,
	uploadSupportAttachment,
	createSupportRequest,
	getTodayLessons,
	removeStripePaymentMethod,
	getStripePaymentMethods,
	getGymAvailableSubscriptions,
	getCurrentGymSubscription,
	getFutureGymSubscription,
	applyGymPromoCode,
	confirmStripePaymentIntent,
	changeGymSubscription,
	scheduleGymSubscription,
	requestGymSubscription,
	getGymInvoices,
	cancelCurrentGymSubscription,
	cancelFutureGymSubscription,
	payUnpaidInvoices,
	updateDiscipline,
	getWorkoutResultsByUser,
	getEInvoices,
	setupEInvoicing,
	cancelEInvoicing,
	getEInvoicingAccount,
	disconnectEInvoicing,
	validateProductSale,
	removeProductSale,
	exportTable,
	getAccountings,
	getUserDocuments,
	deleteUserDocument,
	getPreferences,
	setPreferences,
	getUserSummary,
	getProducts,
	getProduct,
	deleteProduct,
	createProduct,
	updateProduct,
	deleteProductVariant,
	updateProductCombination,
	getProductCombinations
}
