<main class="pay-invoices" [appBackgroundImage]="'assets/img/bg_login.png'">
	<div class="language">
		<ul class="nav navbar-nav ml-auto justify-content-end">
			<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf='languages'>
				<a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
					(click)="false">
					<span class='flag-icon fa-2x'
						[ngClass]='languageService.getLanguage() !== "en" ? ("flag-icon-" + languageService.getLanguage()) : "flag-icon-gb"'>
					</span>
				</a>
				<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
					<ng-container *ngFor='let obj of languages | keyvalue'>
						<a class="dropdown-item" (click)="setLanguage(obj.key)">
							<span class='flag-icon'
								[ngClass]='obj.key !== "en" ? ("flag-icon-" + obj.key) : "flag-icon-gb"'></span>
							{{ obj.value }}
						</a>
					</ng-container>
				</div>
			</li>
		</ul>
	</div>

	<div class="main d-flex">
		<div class="container">
			<div class="row justify-content-md-center">
				<div class="img-logo col-md-9 p-5">
					<img src="assets/img/logo_bianco.png">
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-md-5">
					<h2 class="mb-4 text-white text-center">{{ 'PAGAMENTO_FATTURE_ARRETRATE'|translate }}</h2>
					<div class="card history-card mb-0">
						<div class="card-body p-0">
							<app-table [tableId]="'invoices'" *ngIf="invoices" [data]="invoices">
								<ng-template appTableRow key="total" label="{{ 'FORM.TOTAL'|translate }}" let-item
									align="left">
									{{ item.total_with_tax | decimal }}€
									<i class="bi bi-info-circle-fill ml-1" matTooltipPosition="right"
										matTooltip="{{ item.total | decimal }}€ + {{ item.tax_percentage }}% IVA"></i>
								</ng-template>
								<ng-template appTableRow key="subscription"
									label="{{ 'GENERIC.SUBSCRIPTION'|translate }}" let-item align="center">
									{{ item.gym_subscription?.subscription?.name }}
								</ng-template>
								<ng-template appTableRow key="due_at" label="{{ 'DATA_DI_SCADENZA'|translate }}"
									let-item align="right">
									{{ item.due_at }}
								</ng-template>
							</app-table>
						</div>
					</div>
					<div class="card form-card">
						<form class="card-body d-flex flex-column justify-content-between" [formGroup]="form"
							(submit)='submit($event)'>
							<div class="box-top">
								<h4>{{ 'TOTALE_DA_PAGARE'|translate }}: {{ invoicesTotal | money }}</h4>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('businessName')?.errors }"
									placeholder="{{ 'RAGIONE_SOCIALE'|translate }}" formControlName='businessName'>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('taxCode')?.errors }"
									placeholder="{{ 'FORM.CF'|translate }}" formControlName='taxCode'>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('partitaIva')?.errors }"
									placeholder="{{ 'PARTITA_IVA'|translate }}" formControlName='partitaIva'>

								<div class="d-flex align-items-center mb-3">
									<label class="switch switch-pill switch-info switch-sm m-0 flex-shrink-0 mr-2">
										<input type="checkbox" class="switch-input" id="eBillingEnabled-input"
											formControlName="eBillingEnabled" />
										<span class="switch-slider"></span>
									</label>
									<label class="m-0 text-muted" for="eBillingEnabled-input">
										{{ 'L_AZIENDA_ABILITAT_ALLA_RICEZIONE_DELLA_FATTURAZIONE_ELETTRONICA'|translate
										}}
									</label>
								</div>
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('uniqueCode')?.errors }" placeholder="SDI"
									formControlName='uniqueCode'
									[attr.disabled]="!form.get('eBillingEnabled')?.value || null">
								<input type="text" class="form-control mb-3"
									[ngClass]="{ 'is-invalid': !!form.get('pec')?.errors }" placeholder="PEC"
									formControlName='pec' [attr.disabled]="!form.get('eBillingEnabled')?.value || null">

								<label>Sede legale</label>
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('address')?.errors}"
									placeholder="{{ 'FORM.ADDRESS'|translate }}" formControlName="address" type="text">
								<input class="form-control mb-3" [ngClass]="{'is-invalid': !!form.get('city')?.errors}"
									placeholder="{{ 'FORM.CITY'|translate }}" formControlName="city" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('province')?.errors}"
									placeholder="{{ 'PROVINCIA'|translate }}" formControlName="province" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('country')?.errors}"
									placeholder="{{ 'FORM.COUNTRY'|translate }}" formControlName="country" type="text">
								<input class="form-control mb-3"
									[ngClass]="{'is-invalid': !!form.get('postalCode')?.errors}"
									placeholder="{{ 'FORM.CAP'|translate }}" formControlName="postalCode" type="text">

								<!-- Stripe payment methods -->
								<ul class="payment-methods mb-3" *ngIf="stripePaymentMethods.length > 0">
									<li class="item" *ngFor="let stripePaymentMethod of stripePaymentMethods">
										<input type="radio" formControlName="stripePaymentMethodId"
											[id]="stripePaymentMethod.id" [value]="stripePaymentMethod.id">
										<label class="label text-muted" [attr.for]="stripePaymentMethod.id">
											XXXX XXXX XXXX {{ stripePaymentMethod.card?.last4 }}
										</label>
										<i class="bi bi-trash-fill delete"
											(click)="deleteStripePaymentMethod(stripePaymentMethod.id)"></i>
									</li>
									<li class="new">
										<div class="item-container">
											<input type="radio" formControlName="stripePaymentMethodId" [value]="null"
												id="payment-method-new">
											<label class="label text-muted" for="payment-method-new">
												{{ 'NUOVA_CARTA'|translate }}
											</label>
										</div>
										<div class="card-container"
											[hidden]="form.get('stripePaymentMethodId')?.value !== null">
											<ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
										</div>
									</li>
								</ul>
								<div class="mb-3" *ngIf="stripePaymentMethods.length <= 0">
									<ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
								</div>
							</div>
							<div class="box-bottom">
								<input type="submit" class="btn btn-primary px-4"
									value="{{ 'GENERIC.SEND'|translate }}">
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
