import {
	AfterContentInit,
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy, Output,
	QueryList,
	ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MatTableExporterDirective } from 'mat-table-exporter';
import moment from 'moment';
import { Subscription, debounceTime, distinctUntilChanged, fromEvent, merge, of, startWith, tap } from 'rxjs';
import { TableRowDirective } from 'src/app/directives/table-row.directive';
import { PreferencesTypes } from 'src/app/enums/preferences-types.enum';
import { Requests } from 'src/app/enums/requests';
import { HttpService } from 'src/app/services/http/http.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PreferencesService } from 'src/app/services/preferences/preferences.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TableDataSource } from './table.datasource';

export interface TableActionOption {
	label: string;
	onClick: (event: Event) => void;
	color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';
}

export interface TableSearchOption<T = any> {
	enabled: boolean;
	callback?: (data: T, filter: string) => boolean; // If not specified, all will be filtered as a string
}

export interface TableSortOption<T = any> {
	keys: string[];
	callback?: (data: T, key: string) => string | number; // If not specified all will be sorted as a string
}

export interface TableDateFilterOption {
	enabled: boolean;
	dateAttribute: string; // Name of the attribute to filter with
	default?: {
		from: moment.Moment;
		to: moment.Moment;
	};
}
export interface TableExportOption {
	enabled: boolean;
	keys?: string[]; // If not specified, all will be exported
	hasServerSide?: boolean; // Table id for server-side export
}

export interface TablePaginatorOption {
	enabled: boolean;
}

export interface TableFooterOption<T = any> {
	enabled: boolean;
	columns?: {[key: string]: (data: T) => any};
}

export type TableDataOption<T = any> = T[] | FetchPaginatedData<T>;

export interface PaginatorFetchParams {
	query: string;
	sort: {
		key: string;
		direction: SortDirection;
	};
	page: {
		index: number;
		size: number;
	};
	dates: {
		start: string;
		end: string;
	};
}

export type FetchPaginatedData<T> = (params: PaginatorFetchParams) => Promise<TablePaginator<T> | null>;

export interface TablePaginator<T> {
	data: T[];
	total: number;
}

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent<T = any> implements OnDestroy, AfterContentInit, AfterViewInit {
	/**
	 * Sort and paginator
	 */
	@ViewChild(MatPaginator) matPaginator?: MatPaginator;
	@ViewChild(MatSort) matSort?: MatSort;
	@ViewChild('query') query?: ElementRef<HTMLInputElement>;
	@ViewChild('picker') picker?: ElementRef<HTMLInputElement>;

	/**
	 * Exporter directive
	 */
	@ViewChild(MatTableExporterDirective) exporter?: MatTableExporterDirective;

	/**
	 * Content rows
	 */
	@ContentChildren(TableRowDirective) contentChildren?: QueryList<TableRowDirective>;

	/**
	 * Table options
	 */
	@Input() actions?: TableActionOption[];
	@Input() search?: TableSearchOption;
	@Input() export?: TableExportOption;
	@Input() sort?: TableSortOption;
	@Input() paginator?: TablePaginatorOption;
	@Input() footer?: TableFooterOption;
	@Input() data: TableDataOption = [];
	@Input() tableId = '';
	@Input() noDataText?: string;

	/**
	 * This option is only available when using server side, client side is yet to be implemented
	 */
	@Input() dateFilter?: TableDateFilterOption;

	/**
	 * On item click event
	 */
	@Output() itemClick = new EventEmitter<T>();

	/**
	 * Query model
	 */
	queryModel = '';

	/**
	 * Params
	 */
	params: {[key: string]: any}|null = null;

	/**
	 * Get row keys to displyay the columns
	 */
	rowKeys: string[] = [];

	/**
	 * Get the indexes of the columns we need to hide from the exporter
	 */
	hiddenColumnIndexes: number[] = [];

	/**
	 * Data source
	 */
	dataSource?: TableDataSource<T> | MatTableDataSource<T>;

	/**
	 * Total length of the result set
	 */
	dataLength?: number;

	/**
	 * Ng contents
	 */
	contents?: TableRowDirective[];

	/**
	 * Event subscriptions from inputs and such
	 */
	subscriptions: Subscription[] = [];

	/**
	 * Date filtering
	 */
	dates = this.fb.group({
		startDate: null,
		endDate: null
	});

	/**
	 * IN CONFLICT WITH ALL CREATION
	 * Data filled from server side
	 */
	serverSideData: T[] = [];

	constructor(
		private loader: LoaderService,
		private toast: ToastService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private http: HttpService,
		private dateAdapter: DateAdapter<any>,
		private lang: LanguageService,
		private preferences: PreferencesService
	) { }

	ngOnDestroy(): void {
		/**
		 * Remove al subscriptions
		 */
		this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
	}

	/**
	 * This is to avoid the ExpressionChangedAfterItHasBeenCheckedError
	 */
	ngAfterContentInit(): void {

		if(this.dateFilter && this.dateFilter.enabled){
			this.dates.get('startDate')?.setValue(this.dateFilter?.default?.from || null);
			this.dates.get('endDate')?.setValue(this.dateFilter?.default?.to || null);
		}

		/**
		 * On content children changes
		 */
		this.contentChildren?.changes
			.pipe(startWith(this.contentChildren))
			.subscribe((children: QueryList<TableRowDirective>) => {

				/**
				 * Get row keys
				 */
				this.rowKeys = children.map((item: TableRowDirective) => item.key || '') || [];

				/**
				 * Get hidden column indexexs for the exporter
				 */
				this.hiddenColumnIndexes = this.getHiddenColumIndexes();

				/**
				 * Get ng content
				 */
				this.contents = children.toArray() || [];
			});

		/**
		 * On lang change
		 */
		this.lang.onLangChange((val) => {
			this.dateAdapter.setLocale(val);
		});

		/**
		 * On dates values change
		 */
		this.dates.valueChanges.subscribe((next: { [key: string]: moment.Moment | null }) => {

			/**
			 * If both inputs have are filled or empty
			 * * This is basically to be sure we are filtering only when both fields are filled
			 */
			if ((moment.isMoment(next.endDate) && moment.isMoment(next.startDate)) || (!next.endDate && !next.startDate)) {

				/**
				 * Apply the filter to filter or clear
				 */
				this.fetchServerData();
			}
		});
	}

	ngAfterViewInit(): void {

		/**
		 * Get table preferences (sort, query, paging)
		 */
		this.preferences.get(PreferencesTypes.Tables,this.tableId).then((params) => {

			this.params = (params as {[key: string]: any}|null);

			/**
			 * Load the table deps
			 */
			if (typeof this.data === 'function') {
				this.initServerSide();
			} else {
				this.initClientSide();
			}
		});
	}

	/**
	 * Export mat table in CSV format
	 */
	exportTable(exporter: MatTableExporterDirective): void {

		/**
		 * Export from server-side if data is requested that way
		 */
		if (this.isServerSide() && this.export?.hasServerSide) {

			this.toast.show(this.translate.instant('INIZIANDO_LA_ESPORTAZIONE_NON_ALTERARE_LA_TABELLA_FIN_CHE_SI_SIA_SCARICATO_IL_FILE'));
			this.loader.show();

			/**
			 * Populate keys to display with apposite label
			 */
			const keys: { [key: string]: any } = {};
			this.contents?.forEach((row => {
				if(this.export?.keys && row.key && this.export.keys.includes(row.key || '')){
					keys[row.key] = row.label;
				}
			}));


			/**
			 * TODO: THIS IS A FIX FOR USERS TABLE
			 * Adding columns to the keys
			 * Address, City, CAP, CF
			 */
			if(this.tableId === 'users'){

				keys.address = this.translate.instant('FORM.ADDRESS');
				keys.city = this.translate.instant('FORM.CITY');
				keys.postal_code = this.translate.instant('FORM.CAP');
				keys.tax_code = this.translate.instant('FORM.CF');

			}

			const filters: { [key: string]: any } = {
				keys
			};
			const params: { [key: string]: any } = {};

			if(this.query && this.query.nativeElement.value !== ''){
				params.query = this.query?.nativeElement.value;
			}

			if(this.dateFilter && this.dateFilter.enabled){
				const startDate  = this.dates.get('startDate')?.value?.format('YYYY-MM-DD') || null;
				const endDate  = this.dates.get('endDate')?.value?.format('YYYY-MM-DD') || null;
				if(startDate != null && endDate != null){
					params.startDate = startDate;
					params.endDate = endDate;
				}
			}

			filters.lang = this.translate.currentLang;

			if(params){
				filters.params = params;
			}

			filters.translations = {
				TOTALE: this.translate.instant('FORM.TOTAL')
			};

			this.http.send(Requests.exportTable,{
				urlParams: {
					id: this.tableId
				},
				headers: {
					Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				},
				body: filters
			}).then((blob) => {
				this.loader.hide();
				this.http.downloadFile(blob, 'export.xlsx');
			});

		} else {

			/**
			 * Show/hide loader on export
			 */
			this.exporter?.exportStarted.subscribe(() => {

				/**
				 * Temporary patch
				 */
				setTimeout(() => {
					this.toast.show(this.translate.instant('INIZIANDO_LA_ESPORTAZIONE_NON_ALTERARE_LA_TABELLA_FIN_CHE_SI_SIA_SCARICATO_IL_FILE'));
				}, 100);

				this.loader.show();
			});
			this.exporter?.exportCompleted.subscribe(() => this.loader.hide());

			/**
			 * Launch export
			 */
			exporter.exportTable('csv', {
				fileName: this.toSnakeCase('export'),
			});

		}

	}

	/**
	 * Refresh table data
	 */
	refresh(): void {
		this.ngOnDestroy();
		this.ngAfterViewInit();
	}

	/**
	 * Init the table to load data only server side
	 */
	private async initServerSide(): Promise<void> {
		const sleep = (ms: number): unknown => new Promise((resolve) => setTimeout(resolve, ms));

		/**
		 * Init datasource
		 */
		this.dataSource = new TableDataSource(this.data as FetchPaginatedData<T>);

		/**
		 * Refresh DOM
		 */
		await sleep(0);

		/**
		 * Set search
		 */
		if (this.query) {

			/**
			 * Set from prefs if there is one
			 */
			if(this.params !== null && this.params.hasOwnProperty('query')){
				this.queryModel = this.params.query;
			}

			this.subscriptions.push(
				fromEvent(this.query.nativeElement, 'keyup')
					.pipe(
						debounceTime(300),
						distinctUntilChanged(),
						tap(() => {

							/**
							 * Reset page
							 */
							if (this.matPaginator) {
								this.matPaginator.pageIndex = 0;
							}

							/**
							 * Request a data refresh
							 */
							this.fetchServerData();
						})
					)
					.subscribe()
			);
		}

		/**
		 * Require paginator to enable
		 */
		if (!this.matPaginator) {
			console.warn('Paginator is NOT enabled!!! Ajax function could be unstable');
			// return;
		} else {

			/**
			 * Set paginator from prefs if there are
			 */
			if(this.params !== null && this.params.hasOwnProperty('page')){
				this.matPaginator.pageIndex = this.params.page.index;
				this.matPaginator.pageSize = this.params.page.size;
			}

		}

		/**
		 * Set sort from prefs if there are
		 */
		if(this.params !== null && this.params.hasOwnProperty('sort') && this.matSort){
			this.matSort.sort({
				id: this.params.sort.key,
				start: this.params.sort.direction,
				disableClear: false
			});
		}

		/**
		 * Set dates from prefs if there are
		 */
		if(this.params !== null && this.params.hasOwnProperty('dates') && this.params.dates.start != null && this.params.dates.end != null  && this.dates.get('startDate') && this.dates.get('endDate')){
			this.dates.get('startDate')?.setValue(moment(this.params.dates.start,'YYYY-MM-DD'));
			this.dates.get('endDate')?.setValue(moment(this.params.dates.end,'YYYY-MM-DD'));
		}

		/**
		 * Listen to paginator and sorting changes
		 */
		this.subscriptions.push(
			merge(this.matSort?.sortChange || of({}), this.matPaginator?.page || of({})).subscribe({
				next: () => this.fetchServerData(),
				error: (error: any) => {
					// TODO do somthing with this
					console.error(error);
				},
			})
		);

		/**
		 * Trigger the first fetch
		 */
		this.fetchServerData();
	}

	/**
	 * Init the table to load data only client side
	 */
	private async initClientSide(): Promise<void> {
		const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

		/**
		 * Init datasource
		 */
		this.dataSource = new MatTableDataSource<T>();

		/**
		 * Get table preferences (sort, query, paging)
		 */
		const getPreferences = (): Promise<{[key: string]: any}|null> => (this.preferences.get(PreferencesTypes.Tables,this.tableId) as Promise<{[key: string]: any}|null>);
		const setPreferences = async (preferences: {[key: string]: any}): Promise<void> => {
			await this.preferences.set(PreferencesTypes.Tables, preferences, this.tableId);
		};

		/**
		 * Refresh DOM
		 */
		await sleep(0);

		/**
		 * Set data passed
		 */
		this.dataSource.data = this.data as T[];
		this.dataLength = this.data.length || 0;

		/**
		 * Set paginator
		 */
		if (this.matPaginator) {

			this.dataSource.paginator = this.matPaginator;

			if(this.params && this.params.hasOwnProperty('page')){
				this.matPaginator.pageIndex = this.params.page.index;
				this.matPaginator.pageSize = this.params.page.size;
			}
		}

		/**
		 * Set sorting
		 * If requested, set the custom sort function
		 */
		if (this.matSort) {

			if(this.params && this.params.hasOwnProperty('sort')){
				this.matSort.sort({
					id: this.params.sort.active,
					start: this.params.sort.direction,
					disableClear: false
				});
			}

			this.matSort.sortChange.subscribe((val) => {
				getPreferences().then((preferences) => {
					if(preferences == null){
						preferences = {};
					}

					preferences.sort = val;
					setPreferences(preferences);
				});

			});

			this.dataSource.sort = this.matSort;

			if(this.sort?.callback){
				this.dataSource.sortingDataAccessor = this.sort?.callback;
			}
		}

		/**
		 * Set search
		 */
		if (this.query) {

			const baseQuery = (ev?: Event): void => {

				/**
				 * Reset page
				 */
				if (this.matPaginator && ev) {
					this.matPaginator.pageIndex = 0;
				}

				/**
				 * Set text to filter
				 */
				(this.dataSource as MatTableDataSource<T>).filter = this.queryModel || '';

				getPreferences().then((preferences) => {
					if(preferences == null){
						preferences = {};
					}

					preferences.query = this.queryModel;
					setPreferences(preferences);
				});
			};

			this.subscriptions.push(
				fromEvent(this.query.nativeElement, 'keyup')
					.pipe(
						debounceTime(150),
						distinctUntilChanged(),
						tap(baseQuery.bind(this))
					)
					.subscribe()
			);

			if(this.params && this.params.hasOwnProperty('query')){
				this.queryModel = this.params.query;
				baseQuery.bind(this)();
			}

		}

		/**
		 * If requested, set the custom search filter function
		 */
		if (this.search?.enabled && this.search?.callback) {
			this.dataSource.filterPredicate = this.search?.callback;
		}
	}

	/**
	 * Fetch data from server
	 */
	private async fetchServerData(): Promise<void> {

		const params = {
			query: this.queryModel || '',
			page: {
				index: this.matPaginator?.pageIndex || 0,
				size: this.matPaginator?.pageSize || 5,
			},
			sort: {
				direction: this.matSort?.direction || 'asc',
				key: this.matSort?.active || '',
			},
			dates: {
				start: this.dates.get('startDate')?.value?.format('YYYY-MM-DD') || null,
				end: this.dates.get('endDate')?.value?.format('YYYY-MM-DD') || null
			}
		};

		/**
		 * Calculate the various params to exist
		 */
		const barams = btoa(JSON.stringify(params));
		const thisBarams = btoa(JSON.stringify(this.params));

		/**
		 * Skip if it is the same params
		 */
		if(thisBarams === barams && this.serverSideData.length !== 0){
			return;
		}

		this.preferences.set(PreferencesTypes.Tables,params,this.tableId);
		this.params = params;

		const paginator = await (this.dataSource as TableDataSource<T>).fetch(params);

		/**
		 * Set paginator length
		 */
		this.dataLength = paginator?.total;

		/**
		 * Set data extracted
		 */
		this.serverSideData = paginator?.data || [];

	}

	private toSnakeCase(str: string): string {
		return (
			str &&
			str.split(' ')
				.map((x) => x.toLowerCase())
				.join('_')
		);
	}

	/**
	 * Get hidden column indexes
	 */
	private getHiddenColumIndexes(): number[] {
		/**
		 * If export is not enabled
		 * Or export columns are not specified
		 * Or there's no columns to export to begin with
		 */
		if (
			!this.export?.enabled ||
			!this.export.keys ||
			this.export.keys.length <= 0 ||
			!this.contentChildren ||
			this.contentChildren.length <= 0
		) {
			return [];
		}

		/**
		 * Otherwise return the columns we want to hide from the exporter
		 */
		return this.contentChildren
			.map((item: TableRowDirective, index: number) => index)
			.filter(
				(index: number) =>
					!this.export?.keys?.some((key: string) => key === this.contentChildren?.get(index)?.key)
			);
	}

	/**
	 * Is data server side?
	 */
	private isServerSide(): boolean{
		return typeof this.data === 'function';
	}
}
