import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TableRowDirective } from 'src/app/directives/table-row.directive';
import { TableComponent } from './table.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		TranslateModule,
		MatTableExporterModule,
		MatInputModule,
		MatDatepickerModule,
		MatMomentDateModule
	],
	declarations: [TableComponent, TableRowDirective],
	exports: [TableComponent, TableRowDirective],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
	]
})
export class TableModule {}
