import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppFooterModule,
	AppHeaderModule,
	AppSidebarModule
} from '@coreui/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyCustomHttpLoaderFactory } from './classes/custom-translate-loader';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { GracePeriodDialogComponent } from './components/grace-period-dialog/grace-period-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TableModule } from './components/table/table.module';
import { ToastComponent } from './components/toast/toast.component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { ActivatedInterceptor } from './interceptors/activated.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { GymInterceptor } from './interceptors/gym.interceptor';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { BaseComponent } from './modules/base/base.component';
import { ApplyPromoCodeDialog } from './modules/buy-subscription/apply-promo-code-dialog/apply-promo-code-dialog';
import { BuySubscriptionComponent } from './modules/buy-subscription/buy-subscription.component';
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';
import { LoginComponent } from './modules/login/login.component';
import { PayInvoicesComponent } from './modules/pay-invoices/pay-invoices.component';
import { RegisterComponent } from './modules/register/register.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { ThankYouResetPasswordComponent } from './modules/thank-you-reset-password/thank-you-reset-password.component';
import { WodVisionDialog } from './modules/wod-vision/wod-vision.dialog';
import { PipesModule } from './pipes/pipes.module';
import { HttpService } from './services/http/http.service';

@NgModule({
	declarations: [
		AppComponent,
		BaseComponent,
		BackgroundImageDirective,
		P404Component,
		P500Component,
		LoginComponent,
		ResetPasswordComponent,
		ThankYouResetPasswordComponent,
		ToastComponent,
		LoaderComponent,
		ConfirmationComponent,
		RegisterComponent,
		BuySubscriptionComponent,
		ApplyPromoCodeDialog,
		PayInvoicesComponent,
		GracePeriodDialogComponent,
		WodVisionDialog,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		AppHeaderModule,
		AppFooterModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ModalModule.forRoot(),
		ChartsModule,
		ReactiveFormsModule,
		AngularSvgIconModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: MyCustomHttpLoaderFactory,
				deps: [HttpService],
			},
			defaultLanguage: 'it',
		}),
		NgxStripeModule.forRoot(environment.stripeApiKey),
		PipesModule,
		MatDialogModule,
		TableModule,
		MatTooltipModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GymInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ActivatedInterceptor,
			multi: true,
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
