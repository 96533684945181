import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Errors } from 'src/app/enums/errors.enum';
import { AdminService } from 'src/app/services/admin/admin.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	languages: { [key: string]: string } = {};

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		login: null,
		password: null
	});

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private adminService: AdminService,
		private toast: ToastService,
		private loader: LoaderService,
		public languageService: LanguageService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {

		/**
		 * Load languages
		 */
		this.languages = this.languageService.getAvailableLanguages();

		/**
		 * Rdirect if authenticated
		 */
		if (this.adminService.isAuthenticated()) {
			this.router.navigateByUrl('/dashboard');
		}
	}

	/**
	 * Login
	 */
	login(event: Event): void {
		event.preventDefault();

		/**
		 * Go to DashBoard/ Home
		 */
		this.loader.show();
		this.adminService.login(
			this.form.get('login')?.value,
			this.form.get('password')?.value,
		).then(() => {
			this.loader.hide();

			/**
			 * Redirect dashboard
			 */
			this.router.navigateByUrl('/dashboard');

		}).catch((error: HttpErrorResponse) => {
			this.loader.hide();

			/**
			 * Error catch
			 */
			switch (error?.error?.code) {
				case Errors.ModelNotFound:
				case Errors.LackPermission:
					this.toast.error(this.translate.instant('ASSICURATI_DI_AVER_AVER_INSERITO_LA_TUA_MAIL_E_PASSWORD_CORRETAMENTE'));
					break;
				case Errors.Validation:
					this.toast.error(this.translate.instant('ERRORE_DI_VALIDAZIONE_ASSICURATI_DI_AVER_AVER_INSERITO_LA_TUA_MAIL_E_PASSWORD_CORRETAMENTE'));
					for (const key in error.error.validation) {
						if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
							this.form.get(key)?.setErrors({
								required: true
							});
						}
					}
					break;
				default:
					this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
					console.error(error);
					break;
			}
		});
	}

	setLanguage(val: string): void {
		this.languageService.setLanguage(val);
	}
}
