import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ToastStatus = {
	message: string;
	type: string;
};

@Injectable({
	providedIn: 'root'
})
export class ToastService {

	/**
	 * setTimeout ID
	 */
	timeoutId: any;

	/**
	 *	BehaviorSubject Object
	 */
	private status = new BehaviorSubject<ToastStatus | null>(null);

	constructor() { }

	/**
	 * Subscribe ot changes
	 */
	subscribe(callback: (status: ToastStatus | null) => void): void {
		this.status.subscribe(callback);
	}

	/**
	 *	Set Toast Status to Open
	 */
	show(message: string, timer = 6000): void{

		if (this.timeoutId != null) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		this.status.next({
			message,
			type: 'success'
		});

		this.timeoutId = setTimeout(() => {
			this.timeoutId = null;
			this.status.next(null);
		}, timer ? timer : 6000);
	}

	/**
	 *	Set Toast Status to Open
	 */
	error(message: string, timer = 6000): void {

		if (this.timeoutId != null) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		this.status.next({
			message,
			type: 'danger'
		});

		this.timeoutId = setTimeout(() => {
			this.timeoutId = null;
			this.status.next(null);
		}, timer ? timer : 6000);
	}

	/**
	 *	Set Toast Status to Close
	 */
	hide(): void {
		if (this.timeoutId != null) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		this.status.next(null);
	}
}
