import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[appTableRow]'
})
export class TableRowDirective {

	@Input() alignCell?: 'left' | 'center' | 'right' = 'left';
	@Input() align?: 'left' | 'center' | 'right' = 'left';
	@Input() key?: string;
	@Input() label?: string;

	constructor(public templateRef: TemplateRef<any>) { }
}
