import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { firstValueFrom, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	/**
	 * Locale
	 */
	private localeSubject = new Subject<string>();
	private currentLocale: string | null = null;

	constructor(
		private translate: TranslateService,
		private adapter: DateAdapter<any>
	) { }

	/**
	 * Set the new language
	 */
	async setLanguage(lang: string, silent = false): Promise<void> {

		/**
		 * Change lang
		 */
		await firstValueFrom(this.translate.use(lang));

		/**
		 * Set moment locale
		 */
		moment.locale(lang);

		/**
		 * Save in current var
		 */
		this.currentLocale = lang;

		/**
		 * New lang for adapter
		 */
		this.adapter.setLocale(lang);

		/**
		 * Inform lang change
		 */
		if (!silent) {
			this.localeSubject.next(lang);
		}
	}

	/**
	 * Ritorna le varie lingue disponibili
	 */
	getAvailableLanguages(): { [key: string]: string } {
		return {
			de: 'Deutsche',
			en: 'English',
			es: 'Español',
			fr: 'French',
			it: 'Italiano',
			pt: 'Português',
		};
	}

	/**
	 * Get current language
	 */
	getLanguage(): string {
		return this.translate.currentLang;
	}

	/**
	 * Init app language
	 */
	initLanguage(): void {
		this.setLanguage(navigator.language.split('-')[0], true);
	}

	/**
	 * On locale change
	 */
	onLangChange(callback: (locale: string) => void): void {
		this.localeSubject.subscribe(callback);
	}
}
