import {
	Injectable
} from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationData } from 'src/app/interfaces/confirmation';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationService {

	/**
	 * Subject to comunicate between service and component
	 */
	private status = new Subject<ConfirmationData>();

	/**
	 * Open confirmation dialog
	 */
	open(data: ConfirmationData): void {

		/**
		 * Request confirmation component open
		 */
		this.status.next(data);
	}

	/**
	 * Subscribe to open requests
	 */
	subscribe(callback: (data: ConfirmationData) => void): void {
		this.status.subscribe(callback);
	}
}
