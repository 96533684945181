import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Workout } from 'src/app/interfaces/workout';
import { HttpService } from '../http/http.service';
import { Requests } from 'src/app/enums/requests';
/* eslint-disable @typescript-eslint/dot-notation */

@Injectable({
	providedIn: 'root'
})
export class WorkoutService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Get workout by discipline ID
	 */
	getByDiscipline(id: number, date: string): Promise<Workout> {
		return new Promise((resolve: (data: Workout) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getWorkoutByDiscipline, {
				urlParams: {
					id: id.toString(),
					date
				}
			})
				.then((workout: Workout) => resolve(workout))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Create workout
	 */
	create(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.createWorkout, { body: formData })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Update workout
	 */
	update(formData: FormData): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.updateWorkout, { body: formData })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Delete the workout
	 */
	delete(id: number, date: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.deleteWorkout, { urlParams: { id: id.toString(), date } })
				.then(() => resolve())
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get today's workout
	 */
	today(): Promise<Workout> {
		return new Promise((resolve: (workout: Workout) => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.getTodayWorkout)
				.then((workout: Workout) => resolve(workout))
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}
}
