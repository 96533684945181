import { Directive, ElementRef, Input } from '@angular/core';

/**
 * * This exists because angular 10 won't let you build with a relative path on background URL, something required by cordova
 */

@Directive({
	selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective {
	@Input() appBackgroundImage = '';

	constructor(el: ElementRef) {
		setTimeout(() => {
			$(el.nativeElement).css({
				'background-image': `url(./${this.appBackgroundImage})`,
				'background-repeat': 'no-repeat',
				'background-size': 'cover'
			});
		}, 50);
	}
}
