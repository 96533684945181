import { Injectable } from '@angular/core';
import { Requests } from 'src/app/enums/requests';
import { Gym } from 'src/app/interfaces/gym';
import { GymSubscription } from 'src/app/interfaces/gym-subscription';
import { Invoice } from 'src/app/interfaces/invoice';
import { Subscription } from 'src/app/interfaces/subscription';
import { HttpService } from '../http/http.service';

@Injectable({
	providedIn: 'root'
})
export class GymService {

	constructor(
		private http: HttpService
	) { }

	/**
	 * Get the settings of the gym
	 */
	get(): Promise<Gym> {
		return this.http.send(Requests.getGym);
	}

	/**
	 * Update gym
	 */
	update(formData: FormData): Promise<Gym> {
		return this.http.send(Requests.updateGym, { body: formData });
	}

	/**
	 * Set langague for the gym
	 */
	setLanguage(lang: string): Promise<void> {
		return this.http.send(Requests.updateGym, { body: { lang } });
	}

	/**
	 * Get gym available subscriptions
	 */
	getAvailableSubscriptions(): Promise<Subscription[]> {
		return this.http.send(Requests.getGymAvailableSubscriptions);
	}

	/**
	 * Get the current gym subscription
	 */
	getCurrentGymSubscription(): Promise<GymSubscription | undefined> {
		return this.http.send(Requests.getCurrentGymSubscription);
	}

	/**
	 * Get the future gym subscription
	 */
	getFutureGymSubscription(): Promise<GymSubscription | undefined> {
		return this.http.send(Requests.getFutureGymSubscription);
	}

	/**
	 * Change gym's current subscription
	 */
	changeGymSubscription(data: FormData): Promise<void> {
		return this.http.send(Requests.changeGymSubscription, { body: data });
	}

	/**
	 * Schedule a subscription for the gym when the current one ends
	 */
	scheduleGymSubscription(data: FormData): Promise<void> {
		return this.http.send(Requests.scheduleGymSubscription, { body: data });
	}

	/**
	 * Request for a new gym subscription (contact us)
	 */
	requestGymSubscription(data: FormData): Promise<void> {
		return this.http.send(Requests.requestGymSubscription, { body: data });
	}

	/**
	 * Apply promo code
	 */
	applyPromoCode(promoCode: string): Promise<void> {
		return this.http.send(Requests.applyGymPromoCode, { body: { code: promoCode } });
	}

	/**
	 * Get gym's invoices
	 */
	getInvoices(options?: {
		settlement?: 'paid' | 'unpaid';
		expiry?: 'expired' | 'future';
	}): Promise<Invoice[]> {
		return this.http.send(Requests.getGymInvoices, { queryParams: options });
	}

	/**
	 * Pay unpaid invoices of the gym
	 */
	payUnpaidInvoices(data: FormData): Promise<void> {
		return this.http.send(Requests.payUnpaidInvoices, { body: data });
	}

	/**
	 * Cancel gym's current subscription
	 */
	cancelCurrentGymSubscription(): Promise<void> {
		return this.http.send(Requests.cancelCurrentGymSubscription);
	}

	/**
	 * Cancel gym's future subscription
	 */
	cancelFutureGymSubscription(): Promise<void> {
		return this.http.send(Requests.cancelFutureGymSubscription);
	}
}
