/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-fallthrough */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethod as StripePaymentMethod, StripeCardElementOptions } from '@stripe/stripe-js';
import moment from 'moment';
import { StripeCardComponent, StripeService as NgxStripeService } from 'ngx-stripe';
import { firstValueFrom } from 'rxjs';
import { Errors } from 'src/app/enums/errors.enum';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { Invoice } from 'src/app/interfaces/invoice';
import { GymService } from 'src/app/services/gym/gym.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StripeService } from '../../services/stripe/stripe.service';

@Component({
	selector: 'app-pay-invoices',
	templateUrl: './pay-invoices.component.html',
	styleUrls: ['./pay-invoices.component.scss']
})
export class PayInvoicesComponent implements OnInit {

	/**
	 * Stripe card element
	 */
	@ViewChild(StripeCardComponent, { static: false }) card?: StripeCardComponent;

	/**
	 * User's payments methods
	 */
	stripePaymentMethods: StripePaymentMethod[] = [];

	/**
	 * Stripe options for styling, language and other things
	 */
	cardOptions: StripeCardElementOptions = {
		iconStyle: 'solid',
		hidePostalCode: true,
		style: {
			base: {
				fontWeight: '600',
				fontFamily: 'Bebas Neue',
				fontSize: '15px',
				fontSmoothing: 'antialiased',
				'::placeholder': { color: '#aaa' },
				':-webkit-autofill': { color: '#fce883' }
			},
			invalid: {
				iconColor: '#ff0000',
				color: '#ff0000'
			},
		}
	};

	languages: { [key: string]: string } = {};

	/**
	 * Unpaid gym invoices
	 */
	invoices?: Invoice[];

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		businessName: '',
		taxCode: '',
		partitaIva: '',
		address: '',
		city: '',
		province: '',
		country: '',
		postalCode: '',
		eBillingEnabled: false,
		pec: '',
		uniqueCode: '',
		stripePaymentMethodId: null,
		invoiceIds: [],
	});

	gymCurrency = this.localStorage.getItem(LocalStorageTypes.GymCurrency);

	/**
	 * Expose moment
	 */
	moment = moment;

	/**
	 * Get the sum of the invoices total
	 */
	get invoicesTotal(): number {
		const totals = this.invoices?.map(invoice => isNaN(invoice.total_with_tax as any) ? 0 : parseFloat(invoice.total_with_tax as any));
		return totals && totals.length > 0 ? totals.reduce((prev, current) => prev + current) : 0;
	}

	constructor(
		private fb: FormBuilder,
		private toast: ToastService,
		private loader: LoaderService,
		public languageService: LanguageService,
		private translate: TranslateService,
		private ngxStripeService: NgxStripeService,
		private stripeService: StripeService,
		private gymService: GymService,
		private localStorage: LocalStorageService,
		private router: Router,
	) { }

	ngOnInit(): void {

		/**
		 * Load languages
		 */
		this.languages = this.languageService.getAvailableLanguages();

		/**
		 * Fetch the gym
		 */
		this.fetchGym();

		/**
		 * Get and set stripe payment methods
		 */
		this.fetchStripePaymentMethods();

		/**
		 * Get the unpaid invoices for this gym
		 */
		this.fetchUnpaidInvoices();
	}

	/**
	 * Change subscription
	 */
	async submit(event: Event): Promise<void> {
		event.preventDefault();

		/**
		 * If it's a new stripe payment method
		 */
		if (this.form.get('stripePaymentMethodId')?.value === null) {

			/**
			 * Create the payment method
			 */
			try {
				this.loader.show();
				const result = await firstValueFrom(this.ngxStripeService.createPaymentMethod({
					type: 'card',
					card: this.card!.element
				}));
				this.loader.hide();

				/**
				 * If error
				 */
				if (result?.error) {
					this.toast.error(result.error.message || this.translate.instant('ERRORS.UNKNOWN_ERROR'));
					console.error(result.error);
					return;
				}

				/**
				 * Set the payment method ID to the form
				 */
				this.form.patchValue({ stripePaymentMethodId: result?.paymentMethod?.id });
			} catch (error) {
				this.loader.hide();
				this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
				console.error(error);
			}
		}

		const formData = new FormData();
		for (const key in this.form.value) {
			if (this.form.value[key] !== null) {
				formData.append(key, this.form.value[key]);
			}
		}

		/**
		 * Final success function
		 */
		const success = (): void => {
			this.toast.show(this.translate.instant('LE_FATTURE_SONO_STATE_PAGATE_CON_SUCCESSO_A_BREVE_VERRAI_REINDIRIZZATO_ALLA_DASHBOARD'), 10000);
			setTimeout(() => {
				this.router.navigate(['/', 'dashboard']);
			}, 3000);
		};

		this.loader.show();
		this.gymService.payUnpaidInvoices(formData)
			.then(() => {
				this.loader.hide();
				success();
			})
			.catch((error) => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.Validation:
						for (const key in error.error.validation) {
							if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
								this.form.get(key)?.setErrors({ required: true });
								this.toast.error(this.translate.instant('ERRORS.VALIDATION_ERROR'));
							}
						}
						break;

					/**
					 * Handle errors like insuficient funds and stuff like that
					 */
					case Errors.PaymentFailed:
						if (error.error.message) {
							this.toast.error(error.error.message);
							break;
						}

					/**
					 * Handle secure card action
					 */
					case Errors.ThreeDSConfirmation:
						firstValueFrom(this.ngxStripeService.handleCardAction(error.error.client_secret))
							.then((result) => {
								if (result?.error) {
									this.toast.error(this.translate.instant('IL_PAGAMENTO_STATO_CANCELLATO'));
									console.error(result.error);
								} else if (result?.paymentIntent?.id) {

									/**
									 * Confirm the payment
									 */
									this.loader.show();
									this.stripeService.confirmPaymentIntent(result.paymentIntent.id, 'invoices')
										.then(() => {
											this.loader.hide();
											success();
										})
										.catch(error => {
											this.loader.hide();
											this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
											console.error(error);
										});
								}
							});
						break;
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}

	setLanguage(val: string): void {
		this.languageService.setLanguage(val);
	}

	/**
	 * Get the unpaid invoices for this gym
	 */
	fetchUnpaidInvoices(): void {
		this.loader.show();
		this.gymService.getInvoices({ settlement: 'unpaid', expiry: 'expired' })
			.then(invoices => {
				this.loader.hide();
				this.invoices = invoices;

				/**
				 * Fill the form
				 */
				this.form.patchValue({
					invoiceIds: invoices.map(invoice => invoice.id)
				});
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Delete payment method
	 */
	deleteStripePaymentMethod(paymentMethodId: string): void {
		if (window.confirm(this.translate.instant('SEI_SICURO_DI_VOLER_ELIMINARE_QUESTA_CARTA'))) {
			this.loader.show();
			this.stripeService.removeStripePaymentMethod(paymentMethodId)
				.then(() => {

					/**
					 * Redirect
					 */
					this.loader.hide();
					this.toast.show(this.translate.instant('CARTA_RIMMOSSA_CON_SUCCESSO'));

					/**
					 * Refresh list
					 */
					this.fetchStripePaymentMethods();
				})
				.catch(error => {
					this.loader.hide();

					/**
					 * Error catch
					 */
					switch (error?.error?.code) {
						case Errors.UserNotInGym: break;

						default:
							this.toast.error(this.translate.instant('BUY.REQUEST_CANNOT_BE_PROCESSED'));
							console.error(error);
							break;
					}
				});
		}
	}

	/**
	 * Get and set stripe payment methods
	 */
	fetchStripePaymentMethods(): void {
		this.loader.show();
		this.stripeService.getStripePaymentMethods()
			.then(payment_methods => {
				this.stripePaymentMethods = payment_methods;

				/**
				 * Set first payment method default
				 */
				this.form.get('stripePaymentMethodId')?.setValue(payment_methods[0]?.id || null);
				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					case Errors.UserNotInGym: break;
					default:
						this.toast.error(this.translate.instant('ERRORS.UNKNOWN_ERROR'));
						console.error(error);
						break;
				}
			});
	}

	/**
	 * Get gym
	 */
	fetchGym(): void {
		this.loader.show();
		this.gymService.get()
			.then(gym => {

				/**
				 * Fill the form
				 */
				this.form.patchValue({
					businessName: gym.company,
					taxCode: gym.tax_code,
					partitaIva: gym.partita_iva,
					address: gym.headquarters_address,
					city: gym.headquarters_city,
					province: gym.headquarters_province,
					country: gym.headquarters_country,
					postalCode: gym.headquarters_postal_code,
					eBillingEnabled: gym.e_billing_enabled,
					pec: gym.pec,
					uniqueCode: gym.unique_code,
				});
				this.loader.hide();
			})
			.catch(error => {
				this.loader.hide();

				/**
				 * Error catch
				 */
				switch (error?.error?.code) {
					default:
						this.toast.error(this.translate.instant('ERRORE_SCONOSCIUTO_SE_L_ERRORE_PERSISTE_CONTATTARE_L_ADMINISTRATORE'));
						console.error(error);
						break;
				}
			});
	}
}
